import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { PageModuleDirective } from '../../page-module.directive';
import { AdCarouselModule } from '@yoimo/interfaces';
import { ImageSliderComponent, SliderImage } from '@yoimo/joymo-ui';
@Component({
  standalone: true,
  imports: [ImageSliderComponent, NgIf],
  selector: 'joymo-ads-carousel',
  templateUrl: './ads-carousel.component.html',
  host: { class: 'block' },
})
export class AdCarouselComponent extends PageModuleDirective<
  AdCarouselModule,
  undefined
> {
  sliderImages: SliderImage[] = [];

  initOnServer(): void {}

  initOnBrowser(): void {
    this.sliderImages = this.moduleData.slidesSource.slides.map((slide) => {
      return {
        imageUrl: slide.image,
        linkTo: slide.link,
        openNewTab: !slide.internal,
      };
    });
  }
}
