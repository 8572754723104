<ng-container [ngSwitch]="moduleData.layoutType">
  <yo-grid
    *ngSwitchCase="'GRID'"
    [density]="density(moduleData.density)"
    [mobileDensity]="'normal'"
    [title]="isFiltered ? '' : moduleData.heading"
    [isTagGrid]="moduleData.moduleType === 'NAMED_TAGS_LIST'"
  >
    <li
      yo-grid-item
      *ngFor="
        let item of (elements | async) || [].constructor(DEFAULT_MODULE_SIZE);
        index as i
      "
    >
      <ng-container *ngIf="item; else skeletonTemplate">
        <ng-container
          *ngTemplateOutlet="itemTemplate; context: { itemData: item }"
        >
        </ng-container>
      </ng-container>
    </li>
  </yo-grid>

  <ng-container *ngSwitchCase="'CAROUSEL'" [ngSwitch]="moduleData.moduleType">
    <yo-tag-carousel
      *ngSwitchCase="'NAMED_TAGS_LIST'"
      [title]="isFiltered ? '' : moduleData.heading"
      [density]="density(moduleData.density)"
    >
      <li
        yo-carousel-item
        *ngFor="
          let item of (elements | async) || [].constructor(DEFAULT_MODULE_SIZE);
          index as i
        "
      >
        <ng-container *ngIf="item; else skeletonTemplate">
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: { itemData: item }"
          >
          </ng-container>
        </ng-container>
      </li>
    </yo-tag-carousel>

    <yo-carousel
      *ngSwitchDefault
      [title]="isFiltered ? '' : moduleData.heading"
      [density]="density(moduleData.density)"
    >
      <li
        yo-carousel-item
        *ngFor="
          let item of (elements | async) || [].constructor(DEFAULT_MODULE_SIZE);
          index as i
        "
      >
        <ng-container *ngIf="item; else skeletonTemplate">
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: { itemData: item }"
          >
          </ng-container>
        </ng-container>
      </li>
    </yo-carousel>
  </ng-container>

  <ng-container *ngSwitchDefault>
    This module layout type is not handled. {{ moduleData | json }}
  </ng-container>
</ng-container>

<ng-template #skeletonTemplate>
  <!-- TODO: Handle TAGS_LIST modules -->
  <yo-skeleton-card
    [type]="moduleData.moduleType === 'NAMED_TAGS_LIST' ? 'tag' : 'card'"
  ></yo-skeleton-card>
</ng-template>
