<div
  class="flex flex-column flex-align-center flex-justify-center gap-8 lg:flex-row flex-wrap lg:gap-16"
>
  <ul class="flex flex-align-center gap-24">
    <li *ngFor="let target of shareTargets">
      <!-- ID is used for GTM Tracking -->
      <yo-icon
        (click)="share(target)"
        [icon]="target.icon"
        [id]="['video_share', target.icon].join('_')"
        [size]="useLargeIcons ? 'xxl' : 'xl'"
        [themed]="true"
        class="clickable"
      ></yo-icon>
    </li>
  </ul>
  <small *ngIf="showSupportMessage" class="text-secondary text-xs block">
    {{ localeMap.socialsShareMessage }}
  </small>
</div>
