import { Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import {
  combineLatest,
  shareReplay,
  switchMap,
  tap,
  Observable,
  of,
} from 'rxjs';

import { NamedTagsListModule } from '@yoimo/interfaces';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Firestore } from '@angular/fire/firestore';
import {
  getNamedTagFromSourceList,
  NamedTagWithId,
} from '@yoimo/client-sdk/named-tags';
import { CommonModule } from '@angular/common';
import { BreakpointsService, TagCardComponent } from '@yoimo/joymo-ui';
import { LibScopeService, PROVIDED } from 'shared-lib/core';
import { BlockComponent } from '../../../block/page-block/block.component';
import { PageModuleDirective } from '../../page-module.directive';

@Component({
  standalone: true,
  selector: 'joymo-named-tags-list',
  templateUrl: './named-tags-list.component.html',
  styleUrls: ['./named-tags-list.component.scss'],
  imports: [CommonModule, TagCardComponent, BlockComponent, RouterModule],
})
export class NamedTagsListComponent extends PageModuleDirective<
  NamedTagsListModule & { _id: string },
  NamedTagWithId
> {
  size$?: Observable<'sm' | 'md' | 'lg'>;

  constructor(
    protected override fs: Firestore,
    protected override route: ActivatedRoute,
    @Inject(PROVIDED.scopeService)
    protected override scopeService: LibScopeService,
    @Inject(PLATFORM_ID) platformId: string,
    protected override element: ElementRef,
    private breakpointService: BreakpointsService
  ) {
    super(fs, route, scopeService, platformId, element);
  }

  initOnServer(): void {}

  initOnBrowser(): void {
    this.$items = combineLatest([this.$scope, this.loadData$]).pipe(
      switchMap(([scope, _]) =>
        getNamedTagFromSourceList(this.fs, this.moduleData.listSource, {
          scope: 'CHANNEL',
          scopeId: scope.scopeId,
          limit: this.moduleData.size,
        })
      ),
      tap((data) => {
        this.emitIfEmptyModule(data);
      }),
      shareReplay(1)
    );

    switch (this.moduleData.layoutType) {
      case 'CAROUSEL':
        this.size$ = of(this.moduleData.density === 'NORMAL' ? 'lg' : 'md');
        break;
      case 'GRID':
        if (this.moduleData.density === 'NORMAL') {
          this.size$ = this.breakpointService.getResponsiveValue$<'md' | 'lg'>({
            'xs-only': 'md',
            'sm-up': 'md',
            'md-up': 'lg',
            'lg-up': 'lg',
            'xl-up': 'lg',
          });
          break;
        }
        this.size$ = this.breakpointService.getResponsiveValue$<'sm' | 'md'>({
          'xs-only': 'sm',
          'sm-up': 'sm',
          'md-up': 'sm',
          'lg-up': 'md',
          'xl-up': 'md',
        });
        break;
    }
  }
}
