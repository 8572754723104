import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UIPageModule } from 'shared-lib/core';

import { AbstractPageModule } from '@yoimo/interfaces';
import { AdSpaceComponent } from '../ads/ad-space/ad-space.component.component';
import { AdCarouselComponent } from '../ads/ads-carousel/ads-carousel.component';
import { LiveAndUpcomingComponent } from '../live-and-upcoming/live-and-upcoming.component';
import { VideosListComponent } from '../videos/videos-list/videos-list.component';
import { NamedTagsListComponent } from '../tags/named-tags-list/named-tags-list.component';
import { EventsListComponent } from '../events-list/events-list.component';
import { TagsListComponent } from '../tags/tags-list/tags-list.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    EventsListComponent,
    NamedTagsListComponent,
    VideosListComponent,
    TagsListComponent,
    AdSpaceComponent,
    LiveAndUpcomingComponent,
    AdCarouselComponent,
  ],
  selector: 'joymo-page',
  templateUrl: './page.component.html',
})
export class PageComponent implements OnChanges {
  @Input('pageModulesList') sourceModules?: UIPageModule[];
  modules?: (UIPageModule & { _id: string })[];
  _moduleIds = new Map<string, number>();
  emptyModules: Map<string, boolean> = new Map();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['sourceModules'] &&
      changes['sourceModules'].currentValue !==
        changes['sourceModules'].previousValue
    ) {
      this._moduleIds = new Map();
      this.emptyModules = new Map();
      this.modules = this.sourceModules?.map((sm) => ({
        ...sm,
        _id: this.getModuleId(sm.moduleType),
      }));
    }
  }

  /**
   * Returns the position of the module within the list of same modules.
   * It loops over all modules since modules do not have an id.
   * @param moduleType
   * @param index
   * @returns
   */
  getModuleId(moduleType: AbstractPageModule['moduleType']): string {
    if (!this._moduleIds) {
      throw new TypeError('moduleIds called before it was initialized');
    }
    let id = this._moduleIds.get(moduleType);
    if (id === undefined) {
      id = 1;
    } else {
      id += 1;
    }
    this._moduleIds.set(moduleType, id);
    return moduleType.toLowerCase() + '_' + id;
  }
}
