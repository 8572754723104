import { Component } from '@angular/core';
import { combineLatest, map, merge, shareReplay, switchMap, tap } from 'rxjs';
import {
  getLiveAndUpcomingStreams,
  LiveAndUpcomingQueryOption,
  VideoWithDocId,
} from '@yoimo/client-sdk/videos';
import { PageModuleDirective } from '../page-module.directive';
import { LiveAndUpcomingListModule } from '@yoimo/interfaces';
import { VideosListBaseComponent } from '../videos/videos-list-base/videos-list-base.component';
import { Scope } from '@yoimo/client-sdk/channels';
import { filterPagedResults } from 'shared-lib/core';

@Component({
  standalone: true,
  imports: [VideosListBaseComponent],
  selector: 'joymo-live-and-upcoming',
  templateUrl: './live-and-upcoming.component.html',
})
export class LiveAndUpcomingComponent extends PageModuleDirective<
  LiveAndUpcomingListModule & { _id: string },
  VideoWithDocId
> {
  initOnServer(): void {}

  initOnBrowser(): void {
    this.$items = combineLatest([this.$scope, this.loadData$]).pipe(
      switchMap(([scope, _]) =>
        getLiveAndUpcomingStreams(
          this.fs,
          this.moduleData.listSource,
          this.getLiveAndUpcomingQueryOption(scope)
        )
      ),
      !this.filterPredicate
        ? tap((_) => _)
        : filterPagedResults<VideoWithDocId>(
            this.filterPredicate,
            this.moduleData.size,
            this.pager,
            this.filterPager
          ),
      map((vids) => {
        this.canLoadMore = vids.paging.canLoadNext;
        return vids.data || [{}];
      }),
      tap((videos) => {
        this.isLoading = false;
        this.emitIfEmptyModule(videos);
      }),
      shareReplay(1)
    );
  }

  getLiveAndUpcomingQueryOption(scope: Scope): LiveAndUpcomingQueryOption {
    return {
      scope: scope.scope,
      scopeId: scope.scopeId,
      limit: this.moduleData.size,
      dateRange: this.moduleData.dateRange,
      pager: this.filterPredicate
        ? merge(this.pager, this.filterPager)
        : this.pager,
    };
  }
}
