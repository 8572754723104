<ng-container *ngIf="$itemsMap | async as groups; else skeletonTpl">
  <yo-grouped-grid
    *ngFor="let group of groups | keyvalue : originalOrder; trackBy: trackKey"
    [title]="group.value.label"
    class="block p-y-24"
  >
    <li yo-grid-item *ngFor="let item of group.value.items; index as i">
      <a [routerLink]="[homepageUrl, 'videos', item.docId]">
        <joymo-video-card-builder
          [video]="item"
          size="auto"
          [displayScheduledTime]="true"
          [showRowCards]="true"
        ></joymo-video-card-builder>
      </a>
    </li>
  </yo-grouped-grid>
</ng-container>

<yo-button
  *ngIf="canLoadMore"
  [loading]="isLoading"
  role="footer"
  (click)="loadMore.emit(true)"
  class="m-t-16"
>
  {{ localeMap.loadMoreButtonLabel }}
</yo-button>

<joymo-floating-anchor
  *ngIf="floatingFiltersFragment"
  [label]="localeMap.filtersFloatingButtonLabel"
  [icon]="floatingAnchorIcon"
  [fragment]="floatingFiltersFragment"
  [isVisible]="showFloatingFilters"
></joymo-floating-anchor>

<ng-template #skeletonTpl>
  <ul class="flex flex-column gap-64 m-t-24">
    <li *ngFor="let _ of [].constructor(3)">
      <ul class="flex flex-column md:flex-row w-100 gap-12">
        <li *ngFor="let __ of [].constructor(4)" class="w-100">
          <yo-skeleton-card></yo-skeleton-card>
        </li>
      </ul>
    </li>
  </ul>
</ng-template>
