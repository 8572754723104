import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

import { Observable } from 'rxjs';
import { UIPageListModule, getDensity } from 'shared-lib/core';
import { CommonModule } from '@angular/common';
import {
  CarouselModule,
  GridModule,
  SkeletonCardComponent,
} from '@yoimo/joymo-ui';

@Component({
  standalone: true,
  selector: 'joymo-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
  imports: [CommonModule, GridModule, CarouselModule, SkeletonCardComponent],
})
export class BlockComponent {
  @ContentChild(TemplateRef, { static: false })
  itemTemplate: TemplateRef<unknown> | null = null;

  @Input('module') moduleData!: UIPageListModule;
  @Input('elements') elements!: Observable<any[]>;
  @Input() isFiltered = false;

  readonly density = getDensity;

  DEFAULT_MODULE_SIZE = 6;
}
