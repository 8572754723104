<ng-container *ngIf="video" [ngSwitch]="video.type">
  <yo-video-card
    *ngSwitchCase="'SHORT'"
    [startDate]="video.uploadTime"
    [duration]="video.duration"
    [imageUrl]="video.posterImage"
    [size]="size"
    [isLocked]="!!(isVideoLocked$ | async)"
    [lockText]="localeMap.videoLockText"
    [row]="showRowCards"
  >
    <ng-container yo-card-title>{{ video.name }}</ng-container>
  </yo-video-card>
  <yo-video-card
    *ngSwitchCase="'UPLOAD'"
    [startDate]="video.uploadTime"
    [duration]="video.duration"
    [imageUrl]="video.posterImage"
    [size]="size"
    [isLocked]="!!(isVideoLocked$ | async)"
    [lockText]="localeMap.videoLockText"
    [row]="showRowCards"
  >
    <ng-container yo-card-title>{{ video.name }}</ng-container>
  </yo-video-card>
  <yo-video-card
    *ngSwitchCase="'STREAM'"
    [startDate]="video.startTime"
    [duration]="video.duration || 0"
    [imageUrl]="video.posterImage"
    [scheduledTime]="scheduledTime"
    [displayScheduledTime]="displayScheduledTime"
    [isLive]="video.streamState === 'LIVE'"
    [liveLabel]="localeMap.liveLabel"
    [isUpcoming]="video.streamState === 'SCHEDULED'"
    [size]="size"
    [isLocked]="!!(isVideoLocked$ | async)"
    [lockText]="localeMap.videoLockText"
    [row]="showRowCards"
  >
    <ng-container yo-card-title>{{ video.name }}</ng-container>
  </yo-video-card>
</ng-container>
