import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HeaderModule, PromotionalBlock } from '@yoimo/interfaces';
import { PageCoverModule } from '@yoimo/joymo-ui';
import { Observable } from 'rxjs';

import { PageScope, HeaderType } from 'shared-lib/core';
import { EventWithDocId } from '@yoimo/client-sdk/events';
import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import { PromotionComponent } from '../promotions/promotion.component';
import { SocialShareComponent } from '../social-share/social-share.component';
import { DescriptionBlockComponent } from '../block/description-block/description-block.component';

@Component({
  standalone: true,
  selector: 'joymo-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  imports: [
    PageCoverModule,
    PromotionComponent,
    CommonModule,
    SocialShareComponent,
    DescriptionBlockComponent,
  ],
})
export class PageHeaderComponent {
  @Input() showActions = false;
  @Input() channel?: ChannelWithDocId;
  @Input() event?: EventWithDocId | null;
  @Input() headerModule?: HeaderModule;
  @Input() headerType: HeaderType = 'DEFAULT';
  @Input() pageDescription?: PageScope['pageDescription'];
  @Input() promotionalBlock$?: Observable<PromotionalBlock | undefined>;
}
