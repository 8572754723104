<div class="w-100 flex flex-justify-center">
  <ng-container
    *ngIf="moduleData.link; then adSpaceClickable; else adSpace"
  ></ng-container>
</div>

<ng-template #adSpaceClickable>
  <a [href]="moduleData.link" target="_blank">
    <ng-container *ngIf="true; then adSpace"> </ng-container>
  </a>
</ng-template>

<ng-template #adSpace>
  <picture>
    <source
      media="(min-width: 768px)"
      [srcset]="moduleData.image.largeViewport"
    />
    <img
      class="block m-x-auto md:w-100"
      [src]="moduleData.image.smallViewport || moduleData.image.largeViewport"
    />
  </picture>
</ng-template>
