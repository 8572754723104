<ng-container *ngIf="pageScope">
  <joymo-page-header
    *ngIf="pageScope.activePage && pageScope.activePage.header"
    [channel]="pageScope.channel"
    [event]="pageScope.event"
    [headerModule]="pageScope.activePage.header"
    [headerType]="pageScope.activePage.header.type"
    [pageDescription]="pageScope.pageDescription"
    [showActions]="!(pageScope.userHasAccess$ | async)"
    [promotionalBlock$]="pageScope.promotionalBlock$"
  >
  </joymo-page-header>

  <div class="standard-margins">
    <ng-container *ngIf="pageScope.activePage; else noPageTpl">
      <!-- Single page -->
      <joymo-page
        *ngIf="isSinglePage(pageScope.activePage.modules)"
        [pageModulesList]="pageScope.activePage.modules"
      ></joymo-page>

      <!-- Tabbed pages -->
      <yo-tabs
        *ngIf="!isSinglePage(pageScope.activePage.modules)"
        (onTabSelected)="onTabSelected.emit($event)"
      >
        <yo-tab
          *ngFor="let tab of pageScope.activePage.modules.tabs"
          [id]="tab.tabId"
          [title]="tab.title"
          [active]="tab.selected"
        >
          <joymo-page [pageModulesList]="tab.moduleData"></joymo-page>
        </yo-tab>
      </yo-tabs>
    </ng-container>
  </div>
</ng-container>

<ng-template #noPageTpl>
  <p class="text-lg m-20">{{ localeMap.noActivePageMessage }}</p>
</ng-template>
