<!-- ================================= Shared ================================= -->
<ng-template #defaultFilterTmpl>
  <joymo-date-filters
    [control]="dateRange"
    [clearDateRange$]="clearDateRange$.asObservable()"
    [disableLiveAndUpcoming$]="pastEventSelected$.asObservable()"
  ></joymo-date-filters>
</ng-template>

<ng-template #resetFiltersLink>
  <a
    class="text-link text-md text-semibold flex flex-justify-center gap-4 m-12 clickable reset-filters-link"
    (click)="resetAllFilters()"
  >
    <yo-icon icon="reset"></yo-icon>
    <span>{{ localeMap.attributeResetFilterLabel }}</span>
  </a>
</ng-template>

<header *ngIf="title" class="m-b-16 md:m-b-24">
  <h2 class="heading-sm md:heading-md">{{ title }}</h2>
</header>

<!-- ================================= Desktop ================================= -->
<section
  *ngIf="!(isMobileDevice$ | async)"
  class="large-screen-block w-100 none md:block p-4"
  elevation="1"
>
  <header class="p-20 p-t-16 gap-8 grid">
    <yo-dropdown
      #attributes
      *ngFor="let filter of filters$ | async"
      [control]="filter.control"
      [label]="filter.controlLabel"
      [bindLabel]="filter.bindLabel"
      [options]="filter.options$ | async"
      [placeholder]="filter.placeholder"
      [optionTmpl]="
        filter.isMultiselect
          ? checkboxOptionTmpl
          : filter.cardType === 'EVENT'
          ? cardOptionTmpl
          : undefined
      "
      [isMultiselect]="filter.isMultiselect"
      [multiLabelTmpl]="
        filter.isMultiselect ? checkboxOptionLabelTmpl : undefined
      "
      [groupBy]="filter.groupBy || ''"
      class="block"
      [isLoading]="filter.isLoading"
    >
    </yo-dropdown>
  </header>
  <div class="flex flex-justify-between flex-align-center">
    <ng-container
      *ngIf="showDateFilters"
      [ngTemplateOutlet]="defaultFilterTmpl"
    ></ng-container>
    <ng-container
      *ngIf="isFiltersApplied"
      [ngTemplateOutlet]="resetFiltersLink"
    ></ng-container>
  </div>
</section>

<!-- Template for card type options in the dropdown menu -->
<ng-template #cardOptionTmpl let-item="item" let-size="size">
  <div class="card-option">
    <img [src]="item.icon" />
    <span
      class="m-r-4 text-sm text-semibold text-wrap text-truncate"
      [ngClass]="{ 'text-md': size === 'md' }"
      >{{ item.label }}</span
    >
  </div>
</ng-template>

<!-- Template for checkbox, multiselect type options in the dropdown menu -->
<ng-template #checkboxOptionTmpl let-item="item" let-item$="item$">
  <div class="flex gap-8 flex-justify-start flex-align-center m-x-4">
    <yo-checkbox
      [control]="item$.selected | valueAsFormControl"
      class="block"
    ></yo-checkbox>
    <img [src]="item.icon" class="logo" />
    <span class="text-md text-semibold text-truncate">{{ item.label }}</span>
  </div>
</ng-template>

<!-- Template for checkbox, multiselect type values in the dropdown label -->
<ng-template #checkboxOptionLabelTmpl let-items="items" let-clear="clear">
  <ul
    class="flex gap-8 flex-justify-start flex-align-center m-l-4 multi-select-opt-label"
  >
    <li *ngFor="let item of items">
      <img [src]="item.icon" class="logo" />
    </li>
    <li>
      <p class="text-md text-semibold text-truncate">
        {{ items.length === 1 ? items[0].label : items.length }}
        <span *ngIf="items.length > 1"
          >&nbsp;{{ localeMap.videoFiltersSelectedLabel }}</span
        >
      </p>
    </li>
  </ul>
</ng-template>

<!-- ================================= Mobile ================================= -->
<section
  *ngIf="isMobileDevice$ | async"
  class="block md:none text-md"
  #mobileFilters
>
  <ul
    elevation="1"
    class="flex flex-column w-100 mobile-filter border border-default"
  >
    <li
      #attributes
      *ngFor="let filter of filters$ | async"
      class="p-12 p-l-16 flex flex-align-center gap-12 border-b border-delimiter mobile-filter-option clickable"
    >
      <div
        class="flex gap-12 w-100 flex-align-center"
        (click)="optionOpenOnMobile = filter"
        [ngClass]="{
          'has-selection': !!filter.control.value
        }"
      >
        <span class="mobile-filter-option-label">{{
          filter.controlLabel
        }}</span>
        <span class="mobile-filter-option-value text-truncate">
          <ng-container
            [ngTemplateOutlet]="mobileLabelTmpl"
            [ngTemplateOutletContext]="{ filter: filter }"
          >
          </ng-container>
        </span>
        <yo-icon *ngIf="!filter.control.value" icon="arrow_right"></yo-icon>
      </div>
      <yo-icon
        *ngIf="
          filter.isMultiselect
            ? filter.control.value?.length
            : filter.control.value
        "
        icon="clear_solid"
        [fillSubtleColor]="true"
        [reactOnHover]="true"
        (click)="filter.control.reset()"
      ></yo-icon>
    </li>
    <ng-container
      *ngIf="showDateFilters"
      [ngTemplateOutlet]="defaultFilterTmpl"
    ></ng-container>
    <li *ngIf="isFiltersApplied" class="border-t border-delimiter p-6">
      <ng-container [ngTemplateOutlet]="resetFiltersLink"></ng-container>
    </li>
  </ul>

  <!-- Template that displays selected item/s or the placeholder for a filter -->
  <ng-template #mobileLabelTmpl let-filter="filter">
    <ng-container *ngIf="!filter.isMultiselect">
      {{
        filter.control.value
          ? filter.control.value[filter.bindLabel]
          : filter.placeholder
      }}
    </ng-container>
    <ng-container *ngIf="filter.isMultiselect">
      <ng-container
        *ngIf="filter.control.value && filter.control.value.length"
        [ngTemplateOutlet]="checkboxOptionLabelTmpl"
        [ngTemplateOutletContext]="{ items: filter.control.value }"
      >
      </ng-container>
      <span *ngIf="!filter.control.value || !filter.control.value.length">
        {{ filter.placeholder }}
      </span>
    </ng-container>
  </ng-template>

  <!-- Filter option panel -->
  <yo-bottom-sheet
    [isOpen]="!!optionOpenOnMobile"
    (onClose)="resetMobileFilterPanelData()"
    (onSubmit)="onOptionsPanelSubmit()"
    (onClear)="onResetFilter()"
    [title]="optionOpenOnMobile?.controlLabel"
    [clearLinkLabel]="localeMap.attributeResetFilterLabel"
    [submitButtonLabel]="
      optionOpenOnMobile?.isMultiselect
        ? localeMap.attributeApplyLabel
        : undefined
    "
  >
    <ng-container
      [ngTemplateOutlet]="
        !!optionOpenOnMobile?.groupBy ? groupedListTmpl : cardsTmpl
      "
      [ngTemplateOutletContext]="{
        filter: optionOpenOnMobile,
        options: (optionOpenOnMobile?.options$ | async)
      }"
    >
    </ng-container>
  </yo-bottom-sheet>

  <!-- Template for options as text cards / logo with text cards in the filter panel -->
  <ng-template #cardsTmpl let-filter="filter" let-options="options">
    <ul class="card-grid">
      <ng-container *ngFor="let item of options || [].constructor(4)">
        <li
          *ngIf="item"
          class="text-md text-semibold tag-card clickable"
          [ngClass]="{
            active: isValueSelectedInFilterList(filter, item.value),
            'p-y-16': item.icon
          }"
          (click)="onMobileSelect(filter, item)"
        >
          <img *ngIf="item.icon" [src]="item.icon" />
          <span class="text-center">
            {{ item[filter.bindLabel] }}
          </span>
        </li>
        <ng-container
          [ngTemplateOutlet]="!item ? skeletonCardTmpl : null"
          [ngTemplateOutletContext]="{ type: 'tag' }"
        ></ng-container>
      </ng-container>
    </ul>
  </ng-template>

  <!-- Template for grouped image cards in the filter panel -->
  <ng-template #groupedListTmpl let-filter="filter">
    <ng-container
      *ngIf="filter.options$ | async as options; else groupSkeletonTmpl"
    >
      <ul
        class="flex flex-column gap-8 card-list m-b-8"
        *ngFor="let group of options | listAsMap : filter.groupBy | keyvalue"
      >
        <li
          class="p-x-12 p-y-8 text-sidelines text-center text-secondary text-sm"
        >
          <span class="option-group-name">{{ group.key }}</span>
        </li>
        <ng-container *ngIf="filter.cardType === 'EVENT'">
          <li
            *ngFor="let item of group.value"
            class="p-4 border border-default"
            [ngClass]="{
              active: isValueSelectedInFilterList(filter, item.value)
            }"
            (click)="onMobileSelect(filter, item)"
          >
            <ng-component
              [ngTemplateOutlet]="cardOptionTmpl"
              [ngTemplateOutletContext]="{ item: item, size: 'md' }"
            >
            </ng-component>
          </li>
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="filter.cardType !== 'EVENT' ? cardsTmpl : null"
          [ngTemplateOutletContext]="{ filter: filter, options: group.value }"
        ></ng-container>
      </ul>
    </ng-container>
  </ng-template>
</section>

<ng-template #skeletonCardTmpl let-type="type">
  <yo-skeleton-card
    [attr.type]="type"
    [type]="type"
    size="sm"
    layout="column"
  ></yo-skeleton-card>
</ng-template>

<ng-template #groupSkeletonTmpl>
  <ul class="flex flex-column gap-8 card-list m-b-8 skeleton-card-group">
    <li class="p-x-64 p-y-8 text-sidelines text-center">
      <yo-skeleton-card type="text"></yo-skeleton-card>
    </li>
    <ng-container *ngFor="let item of [].constructor(4)">
      <ng-container
        [ngTemplateOutlet]="skeletonCardTmpl"
        [ngTemplateOutletContext]="{ type: 'card' }"
      ></ng-container>
    </ng-container>
  </ul>
</ng-template>
