import {
  Directive,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  Output,
} from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { VideoWithDocId } from '@yoimo/client-sdk/videos';
import {
  PROVIDED,
  LibScopeService,
  LocaleMapping,
} from '../../../core/src/interfaces';

@Directive({
  selector: '[joymoVideoListBaseDirective]',
})
export class VideoListBaseDirective implements OnDestroy {
  unsubscribe = new Subject();

  @Input() $items?: Observable<VideoWithDocId[]>;
  @Input() canLoadMore = false;
  @Input() isLoading = false;
  @Output() loadMore = new EventEmitter<boolean>();
  @Input() isFiltered = false;
  @Input() showFloatingFilters = false;
  @Input() floatingFiltersFragment?: string;
  floatingAnchorIcon = 'filters';

  homepageUrl: string = '';

  constructor(
    @Inject(LOCALE_ID) protected locale: string,
    @Inject(PROVIDED.scopeService)
    protected scopeService: LibScopeService,
    @Inject(PROVIDED.localeMapping) public localeMap: LocaleMapping
  ) {
    this.scopeService.scope$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (scope) =>
          (this.homepageUrl = this.scopeService.getHomepageUrlFromScope(scope))
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }
}
