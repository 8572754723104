import { ButtonModule, CardModule } from '@yoimo/joymo-ui';
import { Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { RouterModule } from '@angular/router';
import { VideoListBaseDirective } from '../videos-list-base-directive';
import { VideoCardBuilderComponent } from '../../../block/video-card-builder/video-card-builder.component';
import { FloatingAnchorComponent } from '../../../block/floating-anchor/floating-anchor.component';
import { BlockComponent } from '../../../block/page-block/block.component';
import { UIPageListModule } from '../../../../core/src/interfaces';
import {
  densitySizeMap,
  getDensity,
} from '../../../../core/src/utilities/layout';

@Component({
  standalone: true,
  selector: 'joymo-videos-list-base',
  imports: [
    NgIf,
    NgClass,
    BlockComponent,
    CardModule,
    RouterModule,
    ButtonModule,
    VideoCardBuilderComponent,
    FloatingAnchorComponent,
  ],
  templateUrl: './videos-list-base.component.html',
  host: {
    class: 'position-relative',
  },
})
export class VideosListBaseComponent extends VideoListBaseDirective {
  @Input() moduleData!: UIPageListModule;
  @Input() isPaged!: boolean;
  readonly density = getDensity;
  readonly _densitySizeMap = densitySizeMap;
}
