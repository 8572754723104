<a
  *ngIf="isVisible"
  [routerLink]="basePath"
  [fragment]="fragment"
  [skipLocationChange]="true"
  class="flex flex-align-center gap-8 position-relative p-x-16"
>
  <yo-icon *ngIf="icon" [icon]="icon"></yo-icon>
  <span>{{ label }}</span>
</a>
