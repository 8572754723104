import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormatTextDirective } from 'shared-lib/core';

@Component({
  standalone: true,
  imports: [CommonModule, FormatTextDirective],
  selector: 'joymo-help',
  templateUrl: './help.component.html',
  host: { class: 'flex flex-column flex-align-start flex-justify-center' },
})
export class HelpComponent {
  @Input() supportEmailLink?: string | null;
  @Input() faqLink?: string | null;
  @Input() isLarge?: boolean;
  @Input() streamHelpText?: string | null;
}
