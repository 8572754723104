<section
  class="gap-24 md:gap-0 md:border-default md:border-y md:p-y-24 md:p-x-0"
>
  <div
    class="flex flex-column gap-12 flex-align-start flex-justify-start md:p-r-16 lg:p-r-24 w-100"
    [ngClass]="{
      'flex-align-center md:flex-align-start gap-24 md:gap-12':
        centerTitleOnSmallScreens
    }"
  >
    <header
      class="heading-sm md:heading-md"
      [ngClass]="{
        'heading-md text-center md:text-left md:heading-sm':
          centerTitleOnSmallScreens
      }"
    >
      {{ title }}
    </header>
    <p
      *ngIf="description"
      class="text-md text-md-spacious"
      [ngClass]="{
        'border-default border-t md:border-none p-t-16 md:p-t-0 text-center md:text-left w-100':
          centerTitleOnSmallScreens
      }"
    >
      <span *yoFormatText="description"></span>
    </p>
    <ng-content select="[joymo-desc-block-meta]"></ng-content>
  </div>
  <div>
    <ng-content select="[joymo-desc-block-action]"> </ng-content>
  </div>
</section>
