<section class="m-x-auto overflow-hidden">
  <header *ngIf="moduleData.heading" class="flex flex-align-end m-b-12">
    <h2 class="heading-md">{{ moduleData.heading }}</h2>
  </header>

  <ul
    class="flex flex-wrap"
    [ngClass]="{
      'tags p-y-20 flex-justify-center': moduleData.heading,
      'flex-justify-start': !moduleData.heading
    }"
  >
    <li *ngFor="let tag of moduleData.tags">
      <yo-tag [name]="tag" [tagUrl]="[homepageUrl || '', 'tags', tag]"></yo-tag>
    </li>
  </ul>
</section>
