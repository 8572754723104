import { Component } from '@angular/core';
import { PageModuleDirective } from '../../page-module.directive';
import { TagsListModule } from '@yoimo/interfaces';
import { CommonModule } from '@angular/common';
import { TagModule } from '@yoimo/joymo-ui';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'joymo-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
  imports: [CommonModule, TagModule, RouterModule],
})
export class TagsListComponent extends PageModuleDirective<
  TagsListModule,
  string
> {
  initOnServer(): void {}
  initOnBrowser(): void {
    this.emitIfEmptyModule(this.moduleData.tags);
  }
}
