import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule, ToastService, WindowService } from '@yoimo/joymo-ui';
import {
  copyToClipboard,
  isMobile,
  iOS,
  PROVIDED,
  LocaleMapping,
} from 'shared-lib/core';

type ShareTarget = {
  action: 'facebook' | 'whatsapp' | 'messenger' | 'copy' | 'twitter' | 'share';
  /** Environment-specific share URL(s), if applicable */
  actionUrls?: {
    desktop?: string;
    ios?: string;
    android?: string;
  };
  /** JoymoUI-provided icon ID */
  icon: string;
};

const SHARE_TARGETS: ShareTarget[] = [
  {
    action: 'facebook',
    icon: 'facebook',
    actionUrls: { desktop: 'https://www.facebook.com/sharer/sharer.php?u=' },
  },
  {
    action: 'twitter',
    icon: 'twitter',
    actionUrls: { desktop: 'https://twitter.com/intent/tweet?text=' },
  },
  {
    action: 'whatsapp',
    icon: 'whatsapp',
    actionUrls: {
      desktop: 'https://web.whatsapp.com/send?text=',
      android: 'whatsapp://send?text=',
      ios: 'https://api.whatsapp.com/send?text=',
    },
  },
  { action: 'copy', icon: 'link' },
  { action: 'share', icon: 'share' },
];

@Component({
  selector: 'joymo-social-share[shareTitle][shareDescription]',
  standalone: true,
  imports: [CommonModule, IconModule],
  templateUrl: './social-share.component.html',
})
export class SocialShareComponent {
  @Input() shareDescription?: string;
  @Input() shareTitle?: string;
  @Input() showSupportMessage?: boolean;
  @Input() useLargeIcons?: boolean;

  protected readonly shareTargets = this.getShareTargets();

  constructor(
    private toastService: ToastService,
    private windowService: WindowService,
    @Inject(PROVIDED.localeMapping) public localeMap: LocaleMapping
  ) {}

  /** Share to social depending on the user action & current environment */
  protected async share(target: ShareTarget): Promise<void> {
    const { action, actionUrls } = target;
    const { href: currentUrl = '' } = this.windowService.document.location;
    const navigator = this.windowService.window?.navigator;

    // Use native Share API
    if (action === 'share' && navigator?.canShare && navigator?.canShare()) {
      return await navigator.share({
        title: this.shareTitle,
        text: this.shareDescription,
        url: currentUrl,
      });
    }

    // Copy to clipboard
    if (action === 'copy' || (action !== 'share' && !actionUrls)) {
      try {
        await copyToClipboard(
          this.windowService.window,
          this.windowService.document,
          currentUrl
        );
        this.toastService.open('Link copied successfully', {
          type: 'success',
        });
      } catch (error) {
        this.toastService.open(`Link copy failed: ${error}`, {
          type: 'error',
        });
      }
      return;
    }

    if (!actionUrls) throw new Error(`NoActionUrlsFor:${target.action}`);

    // Open ActionUrl in a new tab
    let baseActionUrl: string = actionUrls.desktop || '';
    if (actionUrls.ios && isMobile() && iOS()) {
      baseActionUrl = actionUrls.ios;
    } else if (actionUrls.android && isMobile() && !iOS()) {
      baseActionUrl = actionUrls.android;
    }

    this.openSecureShareUrl(
      this.windowService.document,
      baseActionUrl + encodeURIComponent(currentUrl)
    );
  }

  private getShareTargets() {
    const navigator = this.windowService.window?.navigator;
    const targets =
      navigator?.canShare && navigator?.canShare()
        ? ['facebook', 'twitter', 'whatsapp', 'share']
        : ['facebook', 'twitter', 'whatsapp', 'copy'];

    return SHARE_TARGETS.filter((option) => targets.includes(option.action));
  }

  /**
   * Prevent reverse-tabnabbing
   *
   * @see https://owasp.org/www-community/attacks/Reverse_Tabnabbing
   */
  private openSecureShareUrl(document: Document, targetUrl: string): void {
    const linkElement: HTMLAnchorElement = document.createElement('a');
    linkElement.setAttribute('target', '_blank');
    linkElement.setAttribute('rel', 'noopener noreferrer');
    linkElement.href = targetUrl;
    linkElement.click();
    linkElement.remove();
  }
}
