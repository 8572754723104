import { Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { EventListModule } from '@yoimo/interfaces';
import {
  EventSourceQueryOption,
  EventWithDocId,
} from '@yoimo/client-sdk/events';
import { combineLatest, shareReplay, switchMap, tap } from 'rxjs';

import { ActivatedRoute, RouterModule } from '@angular/router';
import { Firestore } from '@angular/fire/firestore';
import { PageModuleDirective } from '../page-module.directive';
import { BlockComponent } from '../../block/page-block/block.component';
import { CardModule } from '@yoimo/joymo-ui';
import { CommonModule } from '@angular/common';
import {
  LibEventsService,
  LibScopeService,
  PROVIDED,
  getDensity,
  densitySizeMap,
} from 'shared-lib/core';

@Component({
  standalone: true,
  selector: 'joymo-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
  imports: [CommonModule, CardModule, BlockComponent, RouterModule],
})
export class EventsListComponent extends PageModuleDirective<
  EventListModule & { _id: string },
  EventWithDocId
> {
  readonly density = getDensity;
  readonly _densitySizeMap = densitySizeMap;
  constructor(
    protected override fs: Firestore,
    protected override route: ActivatedRoute,
    @Inject(PROVIDED.scopeService)
    protected override scopeService: LibScopeService,
    @Inject(PLATFORM_ID)
    public override platformId: string,
    protected override element: ElementRef,
    @Inject(PROVIDED.eventService)
    private eventsService: LibEventsService
  ) {
    super(fs, route, scopeService, platformId, element);
  }

  initOnServer(): void {}

  initOnBrowser(): void {
    this.$items = combineLatest([this.$scope, this.loadData$]).pipe(
      switchMap(([scope, _]) => {
        const eventQueryOptions: EventSourceQueryOption = {
          scope: scope.scope,
          scopeId: scope.scopeId,
          limit: this.moduleData.size,
        };

        if (this.moduleData.sort) {
          eventQueryOptions.sort = this.moduleData.sort;
          eventQueryOptions.order = this.moduleData.order || 'ASC';
        }
        return this.eventsService.getEvents$(
          this.moduleData.listSource,
          eventQueryOptions,
          undefined
        );
      }),
      tap((data) => {
        this.emitIfEmptyModule(data);
        this.isLoading = false;
      }),
      shareReplay(1)
    );
  }
}
