import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  PLATFORM_ID,
} from '@angular/core';
import {
  PromoCardComponent,
  PlanCardLabels,
  UIState,
  ButtonModule,
} from '@yoimo/joymo-ui';

import { CommonModule, isPlatformServer } from '@angular/common';
import { of, switchMap, Observable, tap } from 'rxjs';

import {
  ResolvedPromoButtonAction,
  ResolvedPromotionalBlock,
  resolvePromotionalBlock,
} from '@yoimo/client-sdk/pages';
import { PromotionalBlock } from '@yoimo/interfaces';
import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import { Firestore } from '@angular/fire/firestore';
import {
  LibTicketOptionsService,
  PROVIDED,
  FormatTextDirective,
} from 'shared-lib/core';

@Component({
  standalone: true,
  imports: [
    PromoCardComponent,
    CommonModule,
    ButtonModule,
    FormatTextDirective,
  ],
  selector: 'joymo-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
  host: { '[hidden]': "state !== 'ready'" },
})
export class PromotionComponent {
  @Input() channel?: ChannelWithDocId;
  @Input() set promotionalBlock$(
    val: Observable<PromotionalBlock | undefined> | undefined
  ) {
    this.promote$ = this.resolvePromoBlock$(val);
  }
  promote$: Observable<ResolvedPromotionalBlock | undefined> = of(undefined);
  pricePlanActions: ResolvedPromoButtonAction[] = [];
  planPrices?: PlanCardLabels[];
  state: UIState = 'idle';

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: string,
    @Inject(PROVIDED.ticketOptionsService)
    private ticketOptionsService: LibTicketOptionsService,
    private fs: Firestore
  ) {}

  resolvePromoBlock$(
    promotionalBlock$: Observable<PromotionalBlock | undefined> | undefined
  ): Observable<ResolvedPromotionalBlock | undefined> {
    if (isPlatformServer(this.platformId) || !promotionalBlock$) {
      return of(undefined);
    }

    return promotionalBlock$.pipe(
      switchMap((promotionalBlock) => {
        if (!this.channel) {
          throw new Error('Channel is undefined');
        }
        return !!promotionalBlock
          ? this.getResolvedPromotionalBlock$(
              promotionalBlock,
              this.channel.docId
            )
          : of(undefined);
      })
    );
  }

  getResolvedPromotionalBlock$(
    promotionalBlock: PromotionalBlock,
    channelId: string
  ): Observable<ResolvedPromotionalBlock | undefined> {
    return resolvePromotionalBlock(this.fs, channelId, promotionalBlock).pipe(
      tap((resolvedBlock) => {
        if (resolvedBlock?.type === 'CARD') {
          this.planPrices = resolvedBlock.actionBlocks.map((block) => {
            this.pricePlanActions.push(block.action);
            return {
              name: block.label,
              price: block.action.text,
              description: block.details,
              discount: block.discountTag,
            };
          });
        }
        // Queuing the state assignement to the next tick gets rid of the ChangeAfterCheck error
        setTimeout(() => (this.state = !resolvedBlock ? 'idle' : 'ready'), 0);
      })
    );
  }

  handlePromoActionClick(action: ResolvedPromoButtonAction): void {
    if (!action.linkTo) {
      throw new Error('No action found');
    }
    if ('url' in action.linkTo) {
      action.linkTo.openInNewContext
        ? window.open(action.linkTo.url)
        : (window.location.href = action.linkTo.url);
      return;
    }
    if ('plan' in action.linkTo) {
      let planOptionId = `plan:${action.linkTo.plan.docId}`;
      if (action.linkTo.periodicity) {
        planOptionId += `:${action.linkTo.periodicity}`;
      }
      this.ticketOptionsService.openOption(planOptionId);
      return;
    }
    if ('ticket' in action.linkTo) {
      this.ticketOptionsService.openOption(
        `ticket:${action.linkTo.event.docId}:${action.linkTo.ticket.id}`
      );
      return;
    }
    if ('sections' in action.linkTo) {
      this.ticketOptionsService.openOptionsList(
        action.linkTo.sections,
        action.linkTo.dialogTitle
      );
    }
  }
}
