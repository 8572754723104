<ng-container *ngIf="headerModule">
  <ng-container [ngSwitch]="headerType">
    <header
      *ngSwitchCase="'TAG'"
      class="heading-md lg:heading-lg standard-margins m-b-24 m-t-40 md:m-t-56"
    >
      {{ headerModule.text }}
    </header>
    <ng-container *ngSwitchCase="'NAMED_TAG'">
      <yo-tag-cover
        *ngIf="
          headerModule.logoImage &&
          headerModule.bannerImages &&
          headerModule.bannerImages !== 'INHERIT' &&
          headerModule.bannerImages !== 'NONE'
        "
        [logo]="headerModule.logoImage"
        [cover]="headerModule.bannerImages['3/1']"
        class="block m-b-20 md:m-b-40 p-12 md:p-16 p-b-0"
      >
      </yo-tag-cover>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <yo-cover
        class="block p-12 p-b-16 md:p-16"
        *ngIf="
          headerModule.bannerImages &&
          headerModule.bannerImages !== 'INHERIT' &&
          headerModule.bannerImages !== 'NONE'
        "
        [imageLg]="headerModule.bannerImages['3/1']"
        [imageSm]="headerModule.bannerImages['3/4']"
        [showOverlay]="!!headerModule.text && !event"
        [showActionOnly]="!!event"
      >
        <div yo-cover-header>
          <h1 class="heading-md lg:heading-xl">
            {{ headerModule.text }}
          </h1>
          <p class="text-md text-semibold">{{ headerModule.subText }}</p>
        </div>
        <joymo-promotion
          *ngIf="showActions"
          yo-cover-action
          [promotionalBlock$]="promotionalBlock$"
          [channel]="channel"
        ></joymo-promotion>
      </yo-cover>
    </ng-container>
  </ng-container>
  <ng-container [ngTemplateOutlet]="pageDescriptionTmpl"> </ng-container>
</ng-container>
<ng-template #pageDescriptionTmpl let-context="context">
  <joymo-description-block
    *ngIf="pageDescription"
    [title]="pageDescription.title"
    [description]="pageDescription.description"
    [centerTitleOnSmallScreens]="
      !!headerModule &&
      headerModule.bannerImages &&
      headerModule.bannerImages !== 'NONE' &&
      headerModule.bannerImages !== 'INHERIT' &&
      !headerModule.bannerImages['3/1']
    "
    class="standard-margins block"
  >
    <div
      joymo-desc-block-action
      class="w-100 flex gap-24 md:gap-64 flex-column md:flex-row flex-align-center flex-justify-center p-l-0 md:p-l-20 md:flex-justify-between"
    >
      <joymo-social-share
        [shareTitle]="pageDescription.title"
        [shareDescription]="pageDescription.description"
        [showSupportMessage]="true"
      >
      </joymo-social-share>
    </div>
    <table
      *ngIf="pageDescription.metaData?.length"
      joymo-desc-block-meta
      elevation="1"
      class="block p-16"
    >
      <tr *ngFor="let item of pageDescription.metaData" class="flex gap-16">
        <td>{{ item.property }}</td>
        <td>{{ item.content }}</td>
      </tr>
    </table>
  </joymo-description-block>
</ng-template>
