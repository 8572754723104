/* istanbul ignore file */

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {
  ButtonModule,
  IconModule,
  IconSize,
  StandardSizes,
} from '@yoimo/joymo-ui';
import { isMobile } from 'shared-lib/core';

@Component({
  selector: 'joymo-info-block[heading]',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.scss'],
  host: { class: 'block w-100' },
  imports: [IconModule, CommonModule, ButtonModule],
  standalone: true,
})
export class InfoBlockComponent {
  @HostBinding('attr.type') @Input() type: 'INFO' | 'ERROR' | 'WARNING' =
    'INFO';
  @HostBinding('attr.skipMobileRounding') @Input() skipMobileRounding = false;
  @Input() heading?: string;
  @Input() description?: string;
  @Input() icon?: string;
  @Input() actionLabel?: string;
  @Input() size: Exclude<StandardSizes, 'md'> = 'lg';
  @Output() onActionClick = new EventEmitter<void>();

  iconSize: IconSize = isMobile() ? 'xl' : 'xxl';

  @HostBinding('class.bg-yellow')
  applyYellowBackground(): boolean {
    return this.type === 'WARNING';
  }
}
