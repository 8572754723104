<ng-container
  *ngIf="type == 'INFO'; then infoTemplate; else errorTemplate"
></ng-container>

<ng-template #infoTemplate>
  <section
    class="text-primary flex flex-column flex-align-center flex-justify-center w-100 h-100 gap-8 p-y-24 md:p-y-48"
  >
    <div
      *ngIf="icon"
      class="m-b-4 md:m-b-8 flex flex-align-center flex-justify-center"
    >
      <yo-icon [icon]="icon" [size]="iconSize"></yo-icon>
    </div>
    <h3 class="heading-xs text-center p-x-16">
      {{ heading }}
    </h3>
    <p
      class="text-center w-100 p-x-16 flex flex-justify-center"
      *ngIf="description"
    >
      <span class="block text-sm">{{ description }}</span>
    </p>
    <yo-button
      *ngIf="actionLabel"
      role="primary"
      class="m-t-8"
      (click)="onActionClick.emit()"
    >
      {{ actionLabel }}
    </yo-button>
  </section>
</ng-template>

<ng-template #errorTemplate>
  <section
    class="flex text-primary gap-16 flex-column lg:flex-row flex-align-center lg:flex-align-end flex-justify-center lg:flex-justify-between"
    [ngClass]="size === 'lg' ? 'p-y-24 p-x-16 lg:p-24' : 'p-12'"
  >
    <div class="flex flex-column">
      <header
        class="flex flex-align-center flex-justify-center lg:flex-justify-start gap-8"
      >
        <yo-icon icon="warning" size="md"></yo-icon>
        <h3 class="heading-xs m-t-2">
          {{ heading }}
        </h3>
      </header>
      <p
        *ngIf="description"
        class="m-t-12 text-center lg:text-left text-semibold"
        [ngClass]="size === 'lg' ? 'text-lg' : 'text-sm'"
      >
        {{ description }}
      </p>
    </div>
    <yo-button
      *ngIf="actionLabel"
      role="error"
      size="large"
      (click)="onActionClick.emit()"
    >
      <span class="text-lg text-semibold">{{ actionLabel }}</span>
    </yo-button>
  </section>
</ng-template>
