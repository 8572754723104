/* istanbul ignore file */

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormatTextDirective } from 'shared-lib/core';
@Component({
  selector: 'joymo-description-block[title]',
  templateUrl: './description-block.component.html',
  styleUrls: ['./description-block.component.scss'],
  imports: [CommonModule, FormatTextDirective],
  standalone: true,
})
export class DescriptionBlockComponent {
  @Input() title!: string;
  @Input() description?: string;
  @Input() centerTitleOnSmallScreens = false;
}
