import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  Inject,
} from '@angular/core';
import { DatePickerComponent, DateRange, IconModule } from '@yoimo/joymo-ui';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil, Observable, filter, tap } from 'rxjs';
import { LocaleMapping, PROVIDED } from 'shared-lib/core';

@Component({
  standalone: true,
  selector: 'joymo-date-filters',
  templateUrl: './date-filters.component.html',
  styleUrls: ['./date-filters.component.scss'],
  imports: [DatePickerComponent, CommonModule, IconModule],
})
export class DateFiltersComponent implements OnInit, OnDestroy {
  @Input() control?: FormControl<DateRange | undefined>;
  calendarControl = new FormControl<DateRange | undefined>(undefined, {
    nonNullable: true,
  });

  @Input() clearDateRange$?: Observable<boolean>;
  @Input() disableLiveAndUpcoming$?: Observable<boolean>;
  @Input() isLiveAndUpcomingDisabled$?: Observable<boolean>;

  // Stores information if a past event was selected previously
  isLiveAndUpcomingDisabled = false;

  @ViewChild(DatePickerComponent) datePicker?: DatePickerComponent;

  private destroy$ = new Subject();

  constructor(
    @Inject(PROVIDED.localeMapping) public localeMap: LocaleMapping
  ) {}

  ngOnInit(): void {
    this.calendarControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        // Update control with date picker changes
        if (val) {
          this.control?.setValue(!val[1] ? [val[0], val[0]] : val);
          return;
        }
        // If date range is cleared set default i.e `live and upcoming`
        this.showLiveAndUpcomingOnly();
      });

    this.clearDateRange$
      ?.pipe(
        takeUntil(this.destroy$),
        filter((res) => !!res)
      )
      .subscribe((val) => {
        this.clearDatePicker();
      });

    this.isLiveAndUpcomingDisabled$ = this.disableLiveAndUpcoming$?.pipe(
      tap((res) => {
        if (res && this.control?.value && this.control.value[1] === undefined) {
          // if a past event is selected and date is currently live and upcoming, reset date.
          this.isLiveAndUpcomingDisabled = true;
          this.showAllStreams();
        }
        if (!res && this.isLiveAndUpcomingDisabled && !this.control?.value) {
          // if viewer selects event with future streams and previously a past event was selected
          // enable and set date to live and upcoming
          this.isLiveAndUpcomingDisabled = false;
          this.showLiveAndUpcomingOnly();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next('');
    this.destroy$.complete();
  }

  showAllStreams() {
    this.clearDatePicker();
    this.control?.setValue(undefined);
  }

  showLiveAndUpcomingOnly() {
    this.clearDatePicker();
    this.control?.setValue([new Date(), undefined]);
  }

  clearDatePicker() {
    if (!this.calendarControl.value) {
      return;
    }
    this.datePicker?.resetDateRange(false);
  }
}
