<ul class="p-16 md:p-24 flex gap-4 w-100">
  <li
    class="p-x-12 p-y-8 flex flex-column flex-align-center md:flex-row gap-8"
    [ngClass]="{
      disabled: isLiveAndUpcomingDisabled$ | async,
      active: control && control.value && control.value[1] === undefined
    }"
    (click)="showLiveAndUpcomingOnly()"
  >
    <yo-icon icon="live_solid"></yo-icon>
    <span class="text-md text-center">{{
      localeMap.attributesLiveAndUpcomingLabel
    }}</span>
  </li>
  <li
    class="p-x-12 p-y-8 flex flex-column flex-align-center md:flex-row gap-8"
    [ngClass]="{ active: !control?.value }"
    (click)="showAllStreams()"
  >
    <yo-icon class="text-primary" icon="stream"></yo-icon>
    <span class="text-md text-center">{{
      localeMap.attributesShowAllStreamsLabel
    }}</span>
  </li>
  <li
    class="p-x-12 p-y-8"
    [ngClass]="{
      active:
        control &&
        control.value &&
        control.value[0] !== undefined &&
        control.value[1] !== undefined
    }"
  >
    <yo-date-picker
      [control]="calendarControl"
      [clearLabel]="localeMap.dateRangeClearLabel"
      [placeholder]="localeMap.dateRangePlaceholder"
      [submitLabel]="localeMap.dateRangeSubmitLabel"
      #datePicker
    >
      <ng-template #labelTemplate let-selectionDisplay="selectionDisplay">
        <div class="flex flex-column flex-align-center md:flex-row gap-8">
          <yo-icon class="text-primary" icon="calendar"></yo-icon>
          <p class="text-md text-center">
            <span *ngIf="selectionDisplay">{{ selectionDisplay }}</span>
            <span *ngIf="!selectionDisplay">{{
              localeMap.attributesFilterByDateLabel
            }}</span>
          </p>
        </div>
      </ng-template>
    </yo-date-picker>
  </li>
</ul>
