/* istanbul ignore file */

import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { PageModuleDirective } from '../../page-module.directive';
import { AdSpaceModule } from '@yoimo/interfaces';
@Component({
  standalone: true,
  selector: 'joymo-ad-space',
  templateUrl: './ad-space.component.html',
  styleUrls: ['./ad-space.component.scss'],
  imports: [NgIf],
  host: { class: 'block' },
})
export class AdSpaceComponent extends PageModuleDirective<
  AdSpaceModule,
  undefined
> {
  initOnServer(): void {}
  initOnBrowser(): void {}
}
