<section class="flex flex-column gap-64 m-y-32">
  <div
    *ngFor="let module of modules; index as index"
    [ngSwitch]="module.moduleType"
    class="w-100"
    [id]="module._id"
    [hidden]="emptyModules.has(module._id)"
  >
    <!-- Client SDK page modules -->
    <joymo-videos-list
      *ngSwitchCase="'VIDEOS_LIST'"
      [moduleData]="module"
      (noResults)="emptyModules.set(module._id, true)"
    ></joymo-videos-list>
    <joymo-videos-list
      *ngSwitchCase="'FILTERED_VIDEOS_LIST'"
      [moduleData]="module"
      (noResults)="emptyModules.set(module._id, true)"
    ></joymo-videos-list>
    <joymo-named-tags-list
      *ngSwitchCase="'NAMED_TAGS_LIST'"
      [moduleData]="module"
      (noResults)="emptyModules.set(module._id, true)"
    ></joymo-named-tags-list>
    <joymo-tags-list
      *ngSwitchCase="'TAGS_LIST'"
      [moduleData]="module"
      (noResults)="emptyModules.set(module._id, true)"
    ></joymo-tags-list>
    <joymo-events-list
      *ngSwitchCase="'EVENTS_LIST'"
      [moduleData]="module"
      (noResults)="emptyModules.set(module._id, true)"
    ></joymo-events-list>
    <joymo-live-and-upcoming
      *ngSwitchCase="'LIVE_AND_UPCOMING_LIST'"
      [moduleData]="module"
      (noResults)="emptyModules.set(module._id, $event)"
    ></joymo-live-and-upcoming>
    <joymo-ad-space *ngSwitchCase="'AD_SPACE'" [moduleData]="module">
    </joymo-ad-space>
    <joymo-ads-carousel *ngSwitchCase="'AD_CAROUSEL'" [moduleData]="module">
    </joymo-ads-carousel>
    <!-- OTHER MODULE CONTAINERS -->
  </div>
</section>
