<joymo-block *ngIf="$items" [module]="moduleData" [elements]="$items">
  <ng-template #itemTemplate let-itemData="itemData">
    <a
      *ngIf="itemData"
      class="text-nodecoration"
      [routerLink]="[homepageUrl, 'tags', itemData.name]"
    >
      <yo-tag-card
        [size]="(size$ | async) || 'md'"
        [name]="itemData.displayName"
        [imageUrl]="itemData.logo"
      >
      </yo-tag-card>
    </a>
  </ng-template>
</joymo-block>
