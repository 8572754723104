<ng-container *ngIf="promote$ | async as promote">
  <yo-promo-card
    *ngIf="promote.type === 'CARD'"
    [cardTitle]="promote.title"
    [discountInfo]="promote.promoBlock ? promote.promoBlock : undefined"
    [planPrices]="planPrices ? planPrices : undefined"
    (onPlanClick)="handlePromoActionClick(pricePlanActions[$event])"
  >
    <footer *ngIf="promote.infoText">
      <p class="text-sm text-semibold" *yoFormatText="promote.infoText"></p>
    </footer>
  </yo-promo-card>

  <yo-button
    *ngIf="promote.type === 'BUTTON'"
    role="primary"
    (click)="handlePromoActionClick(promote.action)"
    size="xlarge"
    [fullWidth]="true"
    id="event_btn_access"
    class="block"
  >
    <p class="p-x-20">
      <span class="text-lg text-semibold">{{ promote.label }}</span>
      <br />
      <span class="text-sm" *ngIf="promote.details">{{ promote.details }}</span>
    </p>
  </yo-button>
</ng-container>
