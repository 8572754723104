<joymo-video-filters
  #videoFiltersTmpl
  *ngIf="filters"
  [id]="'filter-' + moduleData._id"
  [filtersDef]="filters"
  [queryOptionsOverride]="queryOptions"
  [initialSource]="moduleData.listSource"
  (filterChange)="onFilterChange($event)"
  [title]="moduleData.heading"
>
</joymo-video-filters>

<joymo-info-block
  class="m-t-24"
  *ngIf="filters && showNoResults"
  [heading]="localeMap.calendarPageNoStreamsFoundHeading"
  [description]="localeMap.calendarPageNoStreamsFoundDescription"
>
</joymo-info-block>

<joymo-videos-list-base
  *ngIf="moduleData.layoutType !== 'CATEGORIZED_GRID'"
  [$items]="$items"
  [moduleData]="moduleData"
  [canLoadMore]="canLoadMore"
  [isLoading]="isLoading"
  [isPaged]="isPaged"
  (loadMore)="loadMore()"
  [isFiltered]="!!filters"
  [showFloatingFilters]="!!(filters && !(isFiltersIntersecting$ | async))"
  [floatingFiltersFragment]="'filter-' + moduleData._id"
>
</joymo-videos-list-base>

<joymo-categorized-videos-list
  *ngIf="moduleData.layoutType === 'CATEGORIZED_GRID'"
  [categorizer]="moduleData.categorizer"
  [$items]="$items"
  [canLoadMore]="canLoadMore"
  [isLoading]="isLoading"
  [showFloatingFilters]="!!(filters && !(isFiltersIntersecting$ | async))"
  [floatingFiltersFragment]="'filter-' + moduleData._id"
  (loadMore)="loadMore()"
  class="block m-t-24"
>
</joymo-categorized-videos-list>
