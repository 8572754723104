import { CommonModule } from '@angular/common';
import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { TabsModule } from '@yoimo/joymo-ui';

import {
  PageScope,
  UIPage,
  UIPageModule,
  PROVIDED,
  LocaleMapping,
} from 'shared-lib/core';
import { PageComponent } from '../page-modules/page/page.component';
import { PageHeaderComponent } from '../page-header/page-header.component';

@Component({
  standalone: true,
  selector: 'joymo-page-builder',
  templateUrl: './page-builder.component.html',
  imports: [PageHeaderComponent, PageComponent, CommonModule, TabsModule],
})
export class PageBuilderComponent {
  @Input() pageScope?: PageScope;
  @Output() onTabSelected = new EventEmitter<string>();
  constructor(
    @Inject(PROVIDED.localeMapping) public localeMap: LocaleMapping
  ) {}

  protected isSinglePage(
    modules: UIPage['modules']
  ): modules is UIPageModule[] {
    return Array.isArray(modules);
  }
}
