import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { HelpComponent } from '../../block';
import { IconModule } from '@yoimo/joymo-ui';
import { Observable, map } from 'rxjs';
import { LibScopeService, PROVIDED, getMailToLink } from 'shared-lib/core';

@Component({
  standalone: true,
  imports: [CommonModule, IconModule, HelpComponent],
  selector: 'joymo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: { class: 'block m-t-40 lg:m-t-64' },
})
export class FooterComponent implements OnInit {
  @Input() joymoSupportEmail?: string;
  readonly currentYear = new Date().getFullYear();

  readonly channel$ = this.scopeService.listenToChannel();
  protected supportLink$?: Observable<string | null>;
  readonly contactInfo$: Observable<
    ChannelWithDocId['branding']['contactInformation']
  >;

  protected defaultSupportEmail: string | null = null;

  constructor(
    @Inject(PROVIDED.scopeService)
    protected scopeService: LibScopeService
  ) {
    this.contactInfo$ = this.channel$.pipe(
      map((channel) => channel.branding.contactInformation)
    );
  }

  ngOnInit(): void {
    if (this.joymoSupportEmail) {
      this.defaultSupportEmail = getMailToLink(this.joymoSupportEmail);
    }

    this.supportLink$ = this.contactInfo$.pipe(
      map((contactInfo) => {
        if (!contactInfo?.supportEmail) return this.defaultSupportEmail;
        return getMailToLink(contactInfo?.supportEmail);
      })
    );
  }

  getAddressLineType(line: string): 'link' | 'text' {
    if (line.includes('https')) return 'link';
    return 'text';
  }
}
