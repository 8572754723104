<section
  *ngIf="{ channel: channel$ | async, contact: contactInfo$ | async } as ctx"
  class="joymo__footer standard-margins border-t border-default m-b-40 lg:m-b-48"
>
  <ul class="md:grid gap-12">
    <li *ngIf="ctx.channel" class="p-y-24 md:w-80">
      <img
        class="block"
        [src]="ctx.contact?.logoImage || ctx.channel.branding.logoImage"
      />
      <div class="flex flex-column m-t-16 md:m-t-24 gap-16 text-sm">
        <ng-container
          *ngFor="let addr of ctx.contact?.contactAddressLines"
          [ngSwitch]="getAddressLineType(addr)"
        >
          <p *ngSwitchCase="'text'">{{ addr }}</p>
          <a *ngSwitchCase="'link'" [href]="addr" target="_blank">{{ addr }}</a>
        </ng-container>
        <p class="w-50 md:w-100 flex flex-column gap-8">
          <span>{{ ctx.contact?.contactPhone }}</span>
          <a [attr.href]="supportLink$ | async">
            {{ ctx.contact?.contactEmail }}
          </a>
        </p>
      </div>
    </li>
    <li class="p-y-24 md:w-80">
      <yo-icon
        class="block md:m-t-20"
        icon="joymo"
        size="auto"
        height="20"
        width="72"
      ></yo-icon>
      <div class="flex md:flex-column m-t-16 md:m-t-24 gap-16 text-sm">
        <p i18n="@@footerPoweredByJoymoText">
          All streams are powered by Joymo, a&nbsp;dedicated OTT platform for
          your sport.
        </p>
        <ul class="flex flex-column gap-8 w-100">
          <li>
            <a
              href="https://joymo.tv"
              target="_blank"
              i18n="@@footerAboutJoymoLink"
              >About Joymo</a
            >
          </li>
          <li>
            <a
              href="https://www.joymo.tv/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              i18n="@@footerPrivacyPolicyLink"
              >Privacy policy</a
            >
          </li>
          <li>
            <a
              class="clickable privacy_settings"
              onClick="UC_UI.showSecondLayer();"
              i18n="@@footerPrivacySettingsLink"
              >Cookie settings</a
            >
          </li>
          <li>
            <a
              href="https://www.joymo.tv/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
              i18n="@@footerTermsOfServiceLink"
              >Terms of service</a
            >
          </li>
        </ul>
      </div>
    </li>
    <li class="p-y-24 md:w-80">
      <joymo-help
        class="md:m-t-20"
        [ngClass]="{ 'lg:w-70': !ctx.channel }"
        [isLarge]="true"
        [supportEmailLink]="(supportLink$ | async) || defaultSupportEmail"
        [faqLink]="ctx.contact?.faqLink"
        [streamHelpText]="ctx.contact?.streamHelpText"
      ></joymo-help>
    </li>
    <li
      *ngIf="ctx.contact?.socialMediaLinks?.length"
      class="p-y-24 none lg:block text-right"
    >
      <h3 class="heading-xs md:m-t-20" i18n="@@footerFollowUsTitle">
        FOLLOW US
      </h3>
      <ng-container *ngIf="true; then socialLinks"></ng-container>
    </li>
  </ul>
  <footer
    class="p-t-24 lg:p-t-0 flex flex-column-reverse flex-align-center gap-24 md:grid md:gap-16"
  >
    <div class="copyright text-secondary text-center text-xs md:text-left">
      <ng-container
        *ngIf="ctx.contact?.copyrightNotice; else defaultCopyrightTpl"
      >
        {{ ctx.contact?.copyrightNotice }}
      </ng-container>
    </div>
    <div *ngIf="ctx.channel" class="block lg:none">
      <ng-container *ngIf="true; then socialLinks"></ng-container>
    </div>
  </footer>
</section>

<ng-template #socialLinks>
  <ul
    *ngIf="contactInfo$ | async as contact"
    class="flex gap-24 md:gap-16 lg:p-t-24"
  >
    <li *ngFor="let item of contact.socialMediaLinks">
      <a [href]="item.url" target="_blank" class="clickable">
        <yo-icon size="xl" [icon]="item.type" [themed]="true"></yo-icon>
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #defaultCopyrightTpl>
  © 2016-{{ currentYear }} Joymo AS.
  <br />
  <span i18n="@@allRightsReserved">All rights reserved</span>.
</ng-template>
