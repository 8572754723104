import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
  afterRender,
} from '@angular/core';
import {
  combineLatest,
  map,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  tap,
  debounceTime,
  shareReplay,
} from 'rxjs';
import {
  getLiveAndUpcomingStreams,
  getVideosFromSource,
  getVideosFromSourcePaged,
  VideoSourcePagedQueryOption,
  VideoSourceQueryOption,
  VideoWithDocId,
} from '@yoimo/client-sdk/videos';
import { PageModuleDirective } from '../../page-module.directive';
import {
  VideoListModule,
  StaticVideoListSource,
  DynamicVideoListSource,
  FilteredVideoListModule,
  VideoListSource,
} from '@yoimo/interfaces';
import { VideosListBaseComponent } from '../videos-list-base/videos-list-base.component';
import { Scope } from '@yoimo/client-sdk/channels';
import { filterPagedResults } from '../../../../core/src/utilities/filters';
import { AsyncPipe, NgIf, isPlatformServer } from '@angular/common';
import {
  CategoryMap,
  CategorizedFilteredVideoListModule,
  LibScopeService,
  PROVIDED,
  LocaleMapping,
} from '../../../../core/src/interfaces';
import { CategorizedVideosListComponent } from '../categorized-videos-list/categorized-videos-list.component';
import { IconModule } from '@yoimo/joymo-ui';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { InfoBlockComponent } from '../../../block/info/info-block.component';
import {
  VideoFiltersComponent,
  VideoListFilter,
} from '../../../block/video-filters/video-filters.component';
import { Firestore } from '@angular/fire/firestore';

@Component({
  standalone: true,
  imports: [
    VideosListBaseComponent,
    VideoFiltersComponent,
    AsyncPipe,
    NgIf,
    CategorizedVideosListComponent,
    InfoBlockComponent,
    IconModule,
    RouterLink,
  ],
  selector: 'joymo-videos-list',
  templateUrl: './videos-list.component.html',
  host: {
    class: 'position-relative',
  },
})
export class VideosListComponent
  extends PageModuleDirective<
    (
      | VideoListModule
      | FilteredVideoListModule
      | CategorizedFilteredVideoListModule
    ) & { _id: string },
    VideoWithDocId
  >
  implements AfterViewInit
{
  _refreshData$ = new Subject<{
    source: VideoListSource;
    options: VideoSourceQueryOption;
  }>();

  videoListFilter$?: ReplaySubject<VideoListFilter>;

  filters?: FilteredVideoListModule['filters'];
  queryOptions?: Partial<VideoSourceQueryOption>;

  $itemsMap!: Observable<CategoryMap>;
  showNoResults = false;
  @ViewChild('videoFiltersTmpl', { read: ElementRef })
  videoFiltersComponent?: ElementRef;
  private filtersIntersectionObserver?: IntersectionObserver;
  readonly isFiltersIntersecting$ = new Subject<boolean>();

  constructor(
    protected override fs: Firestore,
    protected override route: ActivatedRoute,
    @Inject(PROVIDED.scopeService)
    protected override scopeService: LibScopeService,
    @Inject(PLATFORM_ID) public override platformId: string,
    protected override element: ElementRef,
    @Inject(PROVIDED.localeMapping) public localeMap: LocaleMapping
  ) {
    super(fs, route, scopeService, platformId, element);
  }
  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.moduleData.moduleType === 'FILTERED_VIDEOS_LIST') {
      this.observeFiltersIntersection();
    }

    if ('paged' in this.moduleData.listSource) {
      this.isPaged = !!this.moduleData.listSource.paged;
    }

    this.unsubscribe$.subscribe(() => {
      this.filtersIntersectionObserver?.disconnect();
      this.isFiltersIntersecting$.complete();
    });
  }

  initOnServer(): void {}

  initOnBrowser(): void {
    if (this.moduleData.moduleType === 'FILTERED_VIDEOS_LIST') {
      this.videoListFilter$ = new ReplaySubject<VideoListFilter>(1);
      this.filters = this.moduleData.filters;
    }

    this.$items = combineLatest([this.$scope, this.loadData$]).pipe(
      switchMap(([scope, _]) => {
        this.queryOptions = this.getVideoSourcePagedQueryOption(scope);

        if (this.moduleData.moduleType === 'FILTERED_VIDEOS_LIST') {
          if (!this.videoListFilter$) {
            throw new TypeError('video list filter not created');
          }
          return this.videoListFilter$.pipe(
            debounceTime(500),
            tap((_) => this.resetModule()),
            map((filters) => ({
              source: filters.source,
              options: {
                ...filters.options,
                pager: this.getVideoSourcePagedQueryOption(scope).pager,
              },
            }))
          );
        } else {
          return of({
            source: this.moduleData.listSource,
            options: this.getVideoSourcePagedQueryOption(scope),
          });
        }
      }),
      switchMap(({ source, options }) => {
        return this.getItems(source, options);
      }),
      tap((data) => {
        if (!this.filters) {
          this.emitIfEmptyModule(data);
        } else {
          this.showNoResults = !data.length;
        }
        this.isLoading = false;
      }),
      shareReplay(1)
    );
  }

  observeFiltersIntersection(): void {
    this.filtersIntersectionObserver = new IntersectionObserver(
      ([entry]) => {
        this.isFiltersIntersecting$.next(entry.isIntersecting);
      },
      { threshold: 0 }
    );
    if (!this.videoFiltersComponent) {
      throw new Error('Filters template not found');
    }
    this.filtersIntersectionObserver.observe(
      this.videoFiltersComponent.nativeElement
    );
  }

  getVideoSourceQueryOptions(scope: Scope): VideoSourceQueryOption {
    const options: VideoSourceQueryOption = {
      scope: scope.scope,
      scopeId: scope.scopeId,
      limit: this.moduleData.size,
      dateRange: this.moduleData.dateRange,
    };
    // sorting
    if (this.moduleData.sort) {
      options.sort = [this.moduleData.sort, this.moduleData.order || 'ASC'];
    }

    return options;
  }

  getVideoSourcePagedQueryOption(scope: Scope): VideoSourcePagedQueryOption {
    return {
      ...this.getVideoSourceQueryOptions(scope),
      pager: this.filterPredicate
        ? merge(this.pager, this.filterPager)
        : this.pager,
    };
  }

  onFilterChange(filter: VideoListFilter) {
    if (!this.videoListFilter$) {
      throw new TypeError('Filter change called while no filter was defined');
    }
    this.videoListFilter$.next(filter);
  }

  getStaticItems(
    source: StaticVideoListSource,
    options: VideoSourceQueryOption
  ): Observable<VideoWithDocId[]> {
    return this.$scope.pipe(
      switchMap((scope) => {
        return getVideosFromSource(this.fs, source, options);
      }),
      map((vids) => vids || [{}])
    );
  }

  getDynamicItems(
    source: DynamicVideoListSource,
    options: VideoSourceQueryOption | VideoSourcePagedQueryOption
  ): Observable<VideoWithDocId[]> {
    if (!this.isPagedQuery(options)) {
      throw new TypeError('Should be a paged query');
    }
    const source$ =
      this.moduleData.moduleType === 'FILTERED_VIDEOS_LIST' &&
      this.isLiveAndUpcomingQuery(options.dateRange)
        ? getLiveAndUpcomingStreams(this.fs, source, {
            ...options,
            dateRange: undefined,
          })
        : getVideosFromSourcePaged(this.fs, source, options);

    return source$.pipe(
      !this.filterPredicate
        ? tap((_) => _)
        : filterPagedResults<VideoWithDocId>(
            this.filterPredicate,
            this.moduleData.size,
            this.pager,
            this.filterPager
          ),
      map((data) => {
        // If not paged, fetch data of requested size and close the pager.
        if (!this.isPaged) {
          this.closePagerStream();
        }
        return data;
      }),
      map((vids) => {
        this.canLoadMore = vids.paging.canLoadNext;
        return vids.data || [{}];
      })
    );
  }

  isLiveAndUpcomingQuery(dateRange: VideoListModule['dateRange']): boolean {
    return !!dateRange && !!dateRange[0] && !dateRange[1];
  }

  getItems(
    source: VideoListSource,
    options: VideoSourceQueryOption
  ): Observable<VideoWithDocId[]> {
    const obs =
      'videoIds' in source
        ? this.getStaticItems(source, options)
        : this.getDynamicItems(source, options);
    return obs;
  }

  isPagedQuery(
    query: VideoSourcePagedQueryOption | VideoSourceQueryOption
  ): query is VideoSourcePagedQueryOption {
    return query.pager !== undefined;
  }
}
