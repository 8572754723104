import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
  ButtonModule,
  CardModule,
  GroupedGridComponent,
  SkeletonCardComponent,
} from '@yoimo/joymo-ui';
import { VideoListBaseDirective } from '../videos-list-base-directive';
import { VideoWithDocId } from '@yoimo/client-sdk/videos';

import { VideoCardBuilderComponent } from '../../../block/video-card-builder/video-card-builder.component';
import { FloatingAnchorComponent } from '../../../block/floating-anchor/floating-anchor.component';
import {
  CategoryMap,
  CategoryMapItem,
  CategoryMapValue,
} from '../../../../core/src/interfaces';
import { defaultVideoCategorizer } from '../../../../core/src/utilities';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    RouterModule,
    ButtonModule,
    GroupedGridComponent,
    SkeletonCardComponent,
    VideoCardBuilderComponent,
    FloatingAnchorComponent,
  ],
  selector: 'joymo-categorized-videos-list',
  templateUrl: './categorized-videos-list.component.html',
  styleUrls: ['./categorized-videos-list.scss'],
  host: {
    class: 'position-relative',
  },
})
export class CategorizedVideosListComponent
  extends VideoListBaseDirective
  implements OnInit
{
  DEFAULT_RESULT_SIZE = 4;
  @Input() categorizer?: (video: VideoWithDocId) => {
    key: string;
    label: string;
  };
  $itemsMap!: Observable<CategoryMap>;

  ngOnInit() {
    this.$itemsMap = this.getItemsMap$();
  }

  /**
   * Builds map of videos categorized by the key (Upload time of the videos) based on $items passed by parent component.
   * @returns map of videos categorized by a key
   */
  getItemsMap$(): Observable<CategoryMap> {
    if (!this.$items) {
      throw new Error('No items to group');
    }

    return this.$items.pipe(
      map((vids) => {
        const itemsMap: CategoryMap = new Map<string, CategoryMapValue>();
        const categorizer =
          this.categorizer || defaultVideoCategorizer(this.locale);
        vids.reduce((_itemsMap, video) => {
          const { key, label } = categorizer(video);
          if (!_itemsMap.has(key)) {
            itemsMap.set(key, {
              label,
              items: [],
            });
          }
          _itemsMap.get(key)?.items.push(video);
          return _itemsMap;
        }, itemsMap);

        return itemsMap;
      }),
      shareReplay(1)
    );
  }

  // Preserve original order
  originalOrder(_prev: CategoryMapItem, _next: CategoryMapItem): number {
    return 0;
  }

  trackKey(index: number, item: CategoryMapItem): string {
    return item.key;
  }
}
