import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  takeUntil,
  of,
  take,
} from 'rxjs';
import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  OnInit,
  ElementRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Firestore } from '@angular/fire/firestore';
import { PagedQueryCommand } from '@yoimo/client-sdk/base';
import { Scope } from '@yoimo/client-sdk/channels';
import { isPlatformServer } from '@angular/common';
import { LibScopeService, PROVIDED, UIPageModule } from 'shared-lib/core';

@Directive({
  inputs: ['moduleData'],
  selector: 'pageModule',
  standalone: true,
})
export abstract class PageModuleDirective<M extends UIPageModule, T>
  implements OnInit, OnDestroy
{
  DEFAULT_MODULE_SIZE = 6;
  unsubscribe$ = new Subject<void>();

  protected isPaged = false;
  @Input() filterPredicate?: (item: T) => boolean;
  @Output() noResults = new EventEmitter<boolean>();

  $items?: Observable<T[]>;

  canLoadMore = false;
  homepageUrl?: string;
  isLoading = true;
  pager = new BehaviorSubject<PagedQueryCommand>('init');
  filterPager = new Subject<PagedQueryCommand>();
  $scope = new ReplaySubject<Scope>(1);
  readonly intersectionOptions: IntersectionObserverInit = {
    rootMargin: '0px 0px 100px 0px',
  };

  private _moduleData!: M | UIPageModule;
  private _intersectionObserver?: IntersectionObserver;
  readonly isIntersecting$ = new Subject<void>();
  private _loadData$ = new Subject<boolean>();
  /** Observable emitting when the module should load data, value can be true if it's the first
   * time the observable fires.
   */
  protected loadData$ = this._loadData$.asObservable();

  @Input('moduleData') get moduleData(): M {
    return this._moduleData as M;
  }
  set moduleData(value: UIPageModule) {
    this._moduleData = value;
  }

  constructor(
    protected fs: Firestore,
    protected route: ActivatedRoute,
    @Inject(PROVIDED.scopeService) protected scopeService: LibScopeService,
    @Inject(PLATFORM_ID) public platformId: string,
    protected element: ElementRef
  ) {
    this.$items = of(new Array(this.DEFAULT_MODULE_SIZE));
    this.scopeService.scope$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((scope) => {
        this.homepageUrl = scopeService.getHomepageUrlFromScope(scope);
        this.$scope.next(scope);
      });
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.initOnServer();
      return;
    }
    this.initOnBrowser();
    // Observe intersection only if data loading needs to happen.
    this.observeIntersection$()
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(() => {
        this._loadData$.next(true);
      });
  }

  private observeIntersection$(): Observable<void> {
    this._intersectionObserver = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        return;
      }
      this.isIntersecting$.next();
    }, this.intersectionOptions);
    this._intersectionObserver.observe(this.element.nativeElement);
    return this.isIntersecting$;
  }

  /**
   * Server-only initialization logic
   *
   * @remarks May do nothing if child class doesn't require any specific init logic on this platform
   */
  protected abstract initOnServer(): void;

  /**
   * Browser-only initialization logic
   *
   * @remarks May do nothing if child class doesn't require any specific init logic on this platform
   */
  protected abstract initOnBrowser(): void;

  resetModule(): void {
    this.closePagerStream();
    this.pager = new BehaviorSubject<PagedQueryCommand>('init');
    this.isLoading = true;
    this.canLoadMore = false;
  }

  loadMore() {
    this.isLoading = true;
    this.pager.next('next');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._intersectionObserver?.disconnect();
    this.closePagerStream();
  }

  closePagerStream() {
    this.pager.next('complete');
    this.pager.complete();
    this.filterPager.next('complete');
    this.filterPager.complete();
  }

  emitIfEmptyModule(result: T[]): void {
    !result.length && this.noResults.emit();
  }
}
