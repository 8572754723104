<ul class="flex flex-column gap-8 text-sm" [ngClass]="{ 'gap-16': isLarge }">
  <li>
    <h3
      class="text-semibold"
      [ngClass]="{ 'text-uppercase heading-xs': isLarge }"
      i18n="@@helpBlockNeedHelpTitle"
    >
      Need help?
    </h3>
    <p class="text-regular" [ngClass]="{ 'p-t-24 ': isLarge }" class="p-t-8">
      <ng-container
        *ngIf="streamHelpText != null; else defaultHelpText"
      ></ng-container>
      {{ streamHelpText }}
    </p>
  </li>
  <li class="flex gap-8" [ngClass]="{ 'flex-column': isLarge }">
    <a
      *ngIf="faqLink"
      [href]="faqLink"
      target="_blank"
      i18n="@@helpBlockFaqLabel"
      >FAQ&nbsp;page</a
    >
    <a
      *ngIf="supportEmailLink"
      [ngClass]="{ 'border-l p-l-8': !isLarge && faqLink }"
      [href]="supportEmailLink"
      i18n="@@helpBlockContactUsText"
    >
      Contact&nbsp;us
    </a>
  </li>
</ul>

<ng-template #defaultHelpText>
  <span i18n="@@helpBlockContactUsDescription">
    Feel free to contact us if you face any issues while watching streams.
  </span>
</ng-template>
