/* istanbul ignore file */

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconModule } from '@yoimo/joymo-ui';

@Component({
  standalone: true,
  selector: 'joymo-floating-anchor[label][fragment]',
  imports: [CommonModule, RouterLink, IconModule],
  templateUrl: './floating-anchor.component.html',
  styleUrls: ['./floating-anchor.component.scss'],
  host: {
    class: 'position-sticky block p-b-24',
  },
})
export class FloatingAnchorComponent {
  @Input() label!: string;
  @Input() icon?: string;
  @Input() basePath = './';
  @Input() fragment!: string;
  @Input() isVisible = true;
}
