import { CommonModule, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { ListAsMapPipe, ValueAsFormControlPipe } from 'shared-lib/core';
import {
  DynamicVideoListSource,
  FilteredVideoListModule,
  VideoListSource,
} from '@yoimo/interfaces';
import {
  buildSourceAndQueryOptions,
  FilterDefinition,
  FilterDefinitionValue,
  getAllModuleFilterLabels,
  VideoFilterValue,
  VideoSourceQueryOption,
} from '@yoimo/client-sdk/videos';
import {
  BottomSheetComponent,
  BreakpointsService,
  CheckboxComponent,
  DropdownComponent,
  IconModule,
  WindowService,
  SkeletonCardComponent,
} from '@yoimo/joymo-ui';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  map,
  merge,
  Observable,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { DateFiltersComponent } from './date-filters/date-filters.component';
import { EventWithDocId } from '@yoimo/client-sdk/events';
import {
  LibScopeService,
  LibUserOnboardingService,
  LocaleMapping,
  PROVIDED,
} from '../../../core/src/interfaces';

export interface VideoListFilter {
  source: DynamicVideoListSource;
  options: VideoSourceQueryOption;
}

export type PanelOptionCardType = 'TEXT' | 'LOGO' | 'EVENT';

interface UIFilterDefinition {
  definition: FilterDefinition;
  options$: Observable<
    {
      label: string;
      icon?: string;
      value: string;
    }[]
  >;
  isMultiselect: boolean;
  controlLabel: string;
  control: FormControl;
  cardType: PanelOptionCardType;
  placeholder: string;
  bindLabel: string;
  groupBy?: string;
  isLoading: boolean;
}

@Component({
  standalone: true,
  selector: 'joymo-video-filters',
  templateUrl: './video-filters.component.html',
  styleUrls: ['./video-filters.component.scss'],
  imports: [
    DropdownComponent,
    CommonModule,
    CheckboxComponent,
    ValueAsFormControlPipe,
    ListAsMapPipe,
    IconModule,
    BottomSheetComponent,
    DateFiltersComponent,
    SkeletonCardComponent,
  ],
})
export class VideoFiltersComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() title?: string;
  /**
   * Input corresponding to the video module
   */
  @Input() filtersDef?: FilteredVideoListModule['filters'];
  private _filtersDef$ = new ReplaySubject<FilteredVideoListModule['filters']>(
    1
  );
  @Input() initialSource?: VideoListSource;
  private _initialSource$ = new BehaviorSubject<DynamicVideoListSource>({});

  @Input() queryOptionsOverride?: Partial<VideoSourceQueryOption>;
  private _queryOptionsOverride$ = new ReplaySubject<
    Partial<VideoSourceQueryOption> | undefined
  >(1);

  /**
   * Emits a new value of the filters everytime there is a change
   * in the moduleData input or if the user selects a new value.
   */
  @Output() filterChange = new EventEmitter<VideoListFilter>();
  @ViewChildren('attributes', { read: ElementRef })
  attributes!: QueryList<ElementRef>;

  protected filters$?: Observable<UIFilterDefinition[]>;
  private filterValues: VideoFilterValue[] = [];
  private baseOptions?: VideoSourceQueryOption;
  private destroy$ = new Subject();
  isFiltersApplied = false;
  showDateFilters = false;
  optionOpenOnMobile: UIFilterDefinition | undefined = undefined;
  // control for any date filter that can be applied
  dateRange = new FormControl<[Date | undefined, Date | undefined] | undefined>(
    [new Date(), undefined],
    { nonNullable: true }
  );
  clearDateRange$ = new ReplaySubject<boolean>(1);
  pastEventSelected$ = new ReplaySubject<boolean>(1);

  // Used to prevent rendering duplicate date filter template for mobile and large screens which can cause duplicate filter triggers.
  readonly isMobileDevice$ = this.breakpointsService.isMobile$();

  isValueSelectedInFilterList = (
    filter: UIFilterDefinition,
    value: any
  ): boolean => {
    const selected = filter.control.value;
    if (!Array.isArray(selected)) {
      return selected?.value === value;
    }
    return selected.some((item: any) => item.value === value);
  };

  constructor(
    @Inject(PROVIDED.scopeService)
    private scopeService: LibScopeService,
    @Inject(PLATFORM_ID)
    private platformId: string,
    protected fs: Firestore,
    protected windowService: WindowService,
    protected breakpointsService: BreakpointsService,
    @Inject(PROVIDED.userOnboardingService)
    private userOnboardingService: LibUserOnboardingService,
    @Inject(PROVIDED.localeMapping) public localeMap: LocaleMapping
  ) {}

  ngAfterViewInit(): void {
    this.getHostParametersForOnboarding$().subscribe(
      ([attributes, isMobile]) => {
        this.userOnboardingService.addHostForFeature(
          'ATTRIBUTES',
          attributes.first,
          'bottom',
          isMobile ? 'center' : 'left'
        );
      }
    );
  }

  getHostParametersForOnboarding$(): Observable<
    [attributesRef: any, isMobile: boolean]
  > {
    return combineLatest([this.attributes.changes, this.isMobileDevice$]).pipe(
      takeUntil(this.destroy$),
      delay(500)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changed = (attr: string) =>
      changes[attr] &&
      changes[attr].previousValue !== changes[attr].currentValue;
    if (!changes) {
      return;
    }
    if (changed('filtersDef') && this.filtersDef) {
      this._filtersDef$.next(this.filtersDef);
    }

    if (changed('queryOptionsOverride')) {
      this._queryOptionsOverride$.next(this.queryOptionsOverride);
    }

    if (changed('initialSource')) {
      if (this.initialSource && 'videoIds' in this.initialSource) {
        throw new TypeError('Initial source should be a Dynamic Source list');
      }
      this._initialSource$.next(this.initialSource || {});
    }
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (!this.filtersDef) {
      throw new TypeError('moduleData not initialized correctly');
    }
    this.initForModuleData();
  }

  ngOnDestroy(): void {
    this.clearDateRange$.complete();
    this.destroy$.next('');
    this.destroy$.complete();
  }

  initForModuleData() {
    const scope = this.scopeService.getScope(false);

    if (scope.scope !== 'CHANNEL') {
      throw new TypeError('scope is not of type channel');
    }

    this.filters$ = combineLatest([
      this._filtersDef$,
      this._queryOptionsOverride$,
      this._initialSource$,
    ]).pipe(
      switchMap(([filtersDef, options, initialSource]) =>
        getAllModuleFilterLabels(
          this.fs,
          filtersDef,
          scope,
          undefined,
          initialSource,
          true
        ).pipe(map((filters) => ({ filters, options, initialSource })))
      ),
      map(({ filters, options, initialSource }) => {
        // Set initial filter values
        this.filterValues = filters.map((f) =>
          f.presentation === 'date'
            ? { source: 'date' as const, value: [new Date(), null] }
            : {
                source: f.source,
                value: undefined,
              }
        );

        // Set base query options for initial filter values
        this.baseOptions = {
          ...scope,
          ...options,
        };
        this.filterChange.next(
          buildSourceAndQueryOptions(
            this.filterValues,
            this.baseOptions,
            initialSource
          )
        );

        // Set calendar control change to trigger date filter change
        if (filters.some((filter) => filter.presentation === 'date')) {
          this.showDateFilters = true;
        }

        // Transform filterDefs to UI filter definitions
        return filters
          .filter((filter) => filter.presentation !== 'date')
          .map((filter) => this.transformFilterDefinition(filter));
      }),
      shareReplay(1)
    );

    this.setFilterChangeListeners();
  }

  transformFilterDefinition(filter: FilterDefinition): UIFilterDefinition {
    if (filter.presentation === 'date') {
      throw new Error('No UI definition for date presentation');
    }

    const baseFilterDefinition: UIFilterDefinition = {
      definition: filter,
      // TODO: load options when filter is opened for the first time
      options$: filter.values$,
      isMultiselect: filter.presentation.includes('multi-select'),
      controlLabel: filter.label,
      control: new FormControl(),
      cardType: filter.useIcons ? 'LOGO' : 'TEXT',
      placeholder: 'All',
      bindLabel: 'label',
      groupBy: undefined,
      isLoading: false,
    };

    switch (filter.source) {
      case 'event':
        return this.transformEventFilterValues(baseFilterDefinition);
      case 'attr0':
      case 'attr1':
      case 'attr2':
      case 'attr3':
        return this.transformAttributeFilterValues(baseFilterDefinition);
      default:
        return baseFilterDefinition;
    }
  }

  transformEventFilterValues(
    baseUIFilter: UIFilterDefinition
  ): UIFilterDefinition {
    const filter = baseUIFilter.definition;
    if (!('source' in filter) || filter.source !== 'event') {
      throw new TypeError('Filter is not an valid event filter');
    }

    return {
      ...baseUIFilter,
      options$: filter.values$.pipe(
        map((values) => {
          const pastAndOngoing: FilterDefinitionValue<EventWithDocId>[] = [];
          const upcoming: FilterDefinitionValue<EventWithDocId>[] = [];
          values.forEach((event) => {
            if (event.valueObject.startTime <= new Date()) {
              pastAndOngoing.push({
                ...event,
                group: this.localeMap.videoFiltersPastAndOngoingEventLabel,
              });
            } else {
              upcoming.push({
                ...event,
                group: this.localeMap.videoFiltersUpcomingEventLabel,
              });
            }
          });
          return [...pastAndOngoing, ...upcoming];
        })
      ),
      cardType: 'EVENT',
      groupBy: 'group',
    };
  }

  transformAttributeFilterValues(
    baseUIFilter: UIFilterDefinition
  ): UIFilterDefinition {
    const filter = baseUIFilter.definition;
    if (
      !('source' in filter) ||
      (filter.source !== 'attr0' &&
        filter.source !== 'attr1' &&
        filter.source !== 'attr2' &&
        filter.source !== 'attr3')
    ) {
      throw new TypeError('Filter is not an valid attribute filter');
    }

    return {
      ...baseUIFilter,
      options$: filter.values$.pipe(
        map((values) => {
          return values.map((v) => ({
            ...v,
            group: filter.groups?.find((g) => g.id === v.group)?.label,
          }));
        })
      ),
      groupBy: filter.groups?.length ? 'group' : undefined,
    };
  }

  setFilterChangeListeners() {
    this.filters$
      ?.pipe(
        switchMap((filters) => {
          // filter value changes
          const filterValueChange = filters.map((filter) =>
            filter.control.valueChanges.pipe(
              map((value) => {
                if (filter.definition.presentation === 'date') {
                  throw new Error('Date change handled saperately');
                }
                return {
                  value,
                  source: filter.definition.source,
                };
              })
            )
          );
          // date filter value changes
          if (this.showDateFilters) {
            filterValueChange.push(
              // @ts-ignore TODO: fix it
              this.dateRange.valueChanges.pipe(
                map((val) => ({ value: val, source: 'date' as const }))
              )
            );
          }
          return merge(...filterValueChange);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(({ value, source }: VideoFilterValue) => {
        if (this.optionOpenOnMobile && this.optionOpenOnMobile.isMultiselect) {
          return;
        }
        this.captureValueChange(value, source);
      });
  }

  captureValueChange(eventValue: any, source: VideoFilterValue['source']) {
    if (source === 'event') {
      this.pastEventSelected$.next(
        !!(
          eventValue &&
          eventValue.valueObject &&
          eventValue.valueObject.endTime <= new Date()
        )
      );
    }

    let value = undefined;
    if (source === 'date') {
      value = eventValue;
    } else if (Array.isArray(eventValue)) {
      value = eventValue.length
        ? eventValue.map((item: any) => item.value)
        : undefined;
    } else {
      value = eventValue ? eventValue.value : undefined;
    }
    this.onFilterValueChange({ source, value });
  }

  onFilterValueChange(filterValue: VideoFilterValue) {
    const filter = this.filterValues.find(
      (f) => f.source === filterValue.source
    );
    if (!filter) {
      throw new TypeError('Source was not defined correctly');
    }

    if (!this.baseOptions) {
      throw new TypeError('Base options not set correctly');
    }

    filter.value = filterValue.value;
    const { source, options } = buildSourceAndQueryOptions(
      this.filterValues,
      this.baseOptions,
      this._initialSource$.value
    );
    this.isFiltersApplied = this.filterValues.some((filter) => {
      return filter.source == 'date'
        ? !filter.value || filter.value[1] != undefined
        : !!filter.value;
    });

    this.filterChange.next({ source, options });
  }

  resetAllFilters(): void {
    this.filters$?.pipe(take(1)).subscribe((filters) => {
      filters.map((filter) => filter.control.reset());
    });
    // reset date filter
    this.dateRange.setValue([new Date(), undefined]);
    this.clearDateRange$.next(true);
  }

  /** -------------------------- mobile screen -------------------------- */

  // Reset mobile filter panel data
  resetMobileFilterPanelData(): void {
    this.optionOpenOnMobile = undefined;
  }

  // Hanldes filter panel submit
  onOptionsPanelSubmit(): void {
    const value = this.optionOpenOnMobile?.control.value;
    // TODO: fix type
    // @ts-ignore
    const source = this.optionOpenOnMobile?.definition.source;
    this.captureValueChange(value, source);
    this.resetMobileFilterPanelData();
    // TODO: trigger filter result
  }

  // Handle filter reset
  onResetFilter(): void {
    this.optionOpenOnMobile?.control.reset();
    this.resetMobileFilterPanelData();
  }

  // Set user selected value/s for a filter on mobile
  onMobileSelect(option: UIFilterDefinition, item: any): void {
    if (!option.isMultiselect) {
      option.control.setValue(item);
      this.resetMobileFilterPanelData();
      return;
    }

    let multiSelectedItems = option.control.value || [];
    multiSelectedItems = this.isValueSelectedInFilterList(option, item.value)
      ? multiSelectedItems.filter((i: any) => i.value !== item.value)
      : [...multiSelectedItems, item];
    option.control.setValue(
      multiSelectedItems.length ? multiSelectedItems : undefined
    );
  }
}
