<joymo-block
  *ngIf="$items"
  [module]="moduleData"
  [elements]="$items"
  [isFiltered]="isFiltered"
  [ngClass]="{ 'block m-t-24': isFiltered }"
>
  <ng-template #itemTemplate let-itemData="itemData">
    <a
      class="text-nodecoration"
      [routerLink]="[homepageUrl, 'videos', itemData.docId]"
    >
      <joymo-video-card-builder
        [video]="itemData"
        [size]="_densitySizeMap[density(moduleData.density)]"
      ></joymo-video-card-builder>
    </a>
  </ng-template>
</joymo-block>

<yo-button
  *ngIf="canLoadMore && isPaged"
  [loading]="isLoading"
  role="footer"
  (click)="loadMore.emit(true)"
  class="m-t-16"
>
  {{ localeMap.loadMoreButtonLabel }}
</yo-button>

<joymo-floating-anchor
  *ngIf="floatingFiltersFragment"
  [label]="localeMap.filtersFloatingButtonLabel"
  [icon]="floatingAnchorIcon"
  [fragment]="floatingFiltersFragment"
  [isVisible]="showFloatingFilters"
></joymo-floating-anchor>
