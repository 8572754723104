import { CommonModule, isPlatformServer } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { VideoWithDocId } from '@yoimo/client-sdk/videos';
import { CardModule, ScheduledTime, Size } from '@yoimo/joymo-ui';
import {
  LibAccessControlService,
  LibScopeService,
  LocaleMapping,
  PROVIDED,
} from 'shared-lib/core';
import { Observable, Subject, filter, switchMap, take } from 'rxjs';

@Component({
  standalone: true,
  selector: 'joymo-video-card-builder[video]',
  templateUrl: './video-card-builder.component.html',
  imports: [CardModule, CommonModule],
})
export class VideoCardBuilderComponent implements OnInit, OnDestroy {
  @Input() video!: VideoWithDocId;
  @Input() size?: Size | 'auto';
  @Input() displayScheduledTime?: boolean;
  @Input() showRowCards?: boolean;
  scheduledTime?: ScheduledTime;
  isVideoLocked$?: Observable<boolean>;

  intersectionObserver?: IntersectionObserver;
  private _observeIntersection$ = new Subject<boolean>();
  readonly isCardIntersecting$ = this._observeIntersection$.asObservable().pipe(
    filter((val) => !!val),
    take(1)
  );

  constructor(
    @Inject(PLATFORM_ID) public platformId: string,
    @Inject(PROVIDED.accessControlService)
    private accessControlService: LibAccessControlService,
    @Inject(PROVIDED.scopeService)
    private scopeService: LibScopeService,
    private element: ElementRef,
    @Inject(PROVIDED.localeMapping) public localeMap: LocaleMapping
  ) {}

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.scheduledTime =
      this.video.startTime && this.video.endTime
        ? {
            startTime: this.video.startTime,
            endTime: this.video.endTime,
          }
        : undefined;

    this.observeIntersection();

    this.isVideoLocked$ = this.isCardIntersecting$.pipe(
      switchMap((_) => this.scopeService.listenToChannel()),
      switchMap((channel) => {
        return this.accessControlService.isVideoLocked$(
          channel.docId,
          this.video
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.intersectionObserver?.disconnect();
    this._observeIntersection$.next(false);
    this._observeIntersection$.complete();
  }

  observeIntersection(): void {
    this.intersectionObserver = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        return;
      }
      this._observeIntersection$.next(true);
    });
    this.intersectionObserver.observe(this.element.nativeElement);
  }
}
