<joymo-block *ngIf="$items" [module]="moduleData" [elements]="$items">
  <ng-template #itemTemplate let-itemData="itemData">
    <a
      class="text-nodecoration"
      [routerLink]="[homepageUrl, 'events', itemData.docId]"
    >
      <yo-event-card
        [imageUrl]="itemData.posterImage"
        [size]="_densitySizeMap[density(moduleData.density)]"
      >
        <ng-container yo-card-title>{{ itemData.title }}</ng-container>
      </yo-event-card>
    </a>
  </ng-template>
</joymo-block>
